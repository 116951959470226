/* eslint-disable react/jsx-no-comment-textnodes */
import React from "react";
import "./Margins.css";
import SideNav from "../../UIComponents/SideNav/SideNav";
import NavBar from "../../UIComponents/NavBar/NavBar";
import DashboardContainer from "../DashboardContainer/DashboardContainer/DashboardContainer";
import MainMargins from "../../Components/Margins/Margins";

const MarginsPage = () => {
  return (
    <div className="dashboard-wrapper">
      <SideNav />
      <div className="dashboard-right-sec">
        <NavBar />
        <div className="dashboard-main">
          <DashboardContainer>
            <MainMargins />
          </DashboardContainer>
          {/* <DashboardFooter /> */}
        </div>
      </div>
    </div>
  );
};

export default MarginsPage;
