import { toast } from "react-toastify";

export function success({ message, position = toast.POSITION.TOP_CENTER }) {
  toast.success(message, {
    position,
    className: "toast-success toast-success-message",
    progressClassName: "success-progress-bar",
    autoClose: 3000,
    toastId: 1,
  });
}

export function error({ message, position = toast.POSITION.TOP_CENTER }) {
  toast.error(message, {
    position,
    className: "toast-error toast-error-message",
    progressClassName: "error-progress-bar",
    autoClose: 3000,
    toastId: 1,
  });
}

export const notification = {
  success,
  error,
};
