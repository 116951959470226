import React, { useState } from "react";
import "./index.css";
import CustomTable from "./customTable";
import Accordion from "react-bootstrap/Accordion";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import Card from "react-bootstrap/Card";
import Plus from "../../Images/plus.svg";
import Minus from "../../Images/minus.svg";
import Checked from "../../Images/svg/checked.svg";
import Delete from "../../Images/svg/delete.svg";
import Messaged from "../../Images/svg/speech-bubble.svg";
import Calender from "../../Images/svg/calendar.svg";
import Download from "../../Images/download.png";
import Shares from "../../Images/shares.png";
import Button from "react-bootstrap/Button";
import LogModal from "../../UIComponents/Modal/CustomModal";
import MutiSelect from "../../UIComponents/MutliSelect";
import Reset from "../../Images/svg/reset.svg";
import { useGetApprovalsQuery } from "../../Redux/services/allocations/approvals";
import Cookies from "js-cookie";

const departmentoptions = [
  { value: "Mobiles", label: "Mobiles" },
  {
    value: "Cards & Communications Trading",
    label: "Cards & Communications Trading",
  },
  { value: "Meat", label: "Meat" },
  { value: "Poultry", label: "Poultry" },
  { value: "Fishery", label: "Fishery" },
];

const DashboardTable = () => {
  const token = Cookies.get("token");
  const [modalShow2, setModalShow2] = useState(false);
  const [modalShow3, setModalShow3] = useState(false);
  const [modalShow4, setModalShow4] = useState(false);
  const [modalShow1, setModalShow1] = useState(false);
  const { data: getApprovals } = useGetApprovalsQuery(token);
  console.log("getApprovals", getApprovals);
  const handleSubmitModal = () => {
    setModalShow2(true);
  };
  const handleRejectModal = () => {
    setModalShow3(true);
    setModalShow2(false);
  };
  const handleDownload = () => {
    setModalShow4(true);
  };
  const handleMessage = () => {
    setModalShow1(true);
  };
  const [openCardsAccordion1, setOpenCardsAccordion1] = useState([]);
  const handleToggleAccordion1 = (cardKey) => {
    // setOpenCardsAccordion1((prevOpenCards) =>
    //   prevOpenCards.includes(cardKey)
    //     ? prevOpenCards.filter((key) => key !== cardKey)
    //     : [...prevOpenCards, cardKey]
    // );
    setOpenCardsAccordion1([cardKey]);
  };
  const lastIcons = () => {
    return (
      <div className="date-style">
        <p>12th</p>
        <div onClick={handleSubmitModal}>
          <img src={Checked} alt="checked" />
        </div>
        <div onClick={handleRejectModal}>
          <img src={Delete} alt="delete" />
        </div>
        <div onClick={handleMessage}>
          <img src={Messaged} alt="messaged" />
        </div>
      </div>
    );
  };

  const allApp = [];

  const data = getApprovals || [];
  console.log("data1", getApprovals);
  const data1 = [
    { id: 1, name: "John", age: 25 },
    { id: 2, name: "Jane", age: 30 },
  ];

  // const data = React.useMemo(() => getApp, [getApp]);

  // const data = React.useMemo(
  //   () => [
  //     {
  //       checkbox: <input type="checkbox" />,
  //       col1: "1",
  //       col11: "GNF",
  //       col2: "BACC",
  //       col3: "Service",
  //       col4: "Display",
  //       col5: "Floor Display Branded",
  //       col6: "Personal Care",
  //       col7: "10,000",
  //       col8: lastIcons(),
  //     },
  //     {
  //       checkbox: <input type="checkbox" />,
  //       col1: "2",
  //       col11: "GNF",
  //       col2: "GNF",
  //       col3: "BACC",
  //       col4: "Rebate",
  //       col5: "Display",
  //       col6: "Gondola Display Branded",
  //       col7: "Personal Care",
  //       col8: lastIcons(),
  //     },
  //     {
  //       checkbox: <input type="checkbox" />,
  //       col1: "3",
  //       col11: "GNF",
  //       col2: "BACC",
  //       col3: "Service",
  //       col4: "Display",
  //       col5: "Floor Display Branded",
  //       col6: "Personal Care",
  //       col7: "10,000",
  //       col8: lastIcons(),
  //     },
  //     {
  //       checkbox: <input type="checkbox" />,
  //       col1: "4",
  //       col11: "GNF",
  //       col2: "GNF",
  //       col3: "BACC",
  //       col4: "Rebate",
  //       col5: "Display",
  //       col6: "Gondola Display Branded",
  //       col7: "Personal Care",
  //       col8: lastIcons(),
  //     },
  //   ],
  //   []
  // );
  return (
    <div className="table1">
      {/* <div className="table-section"> */}
      <Accordion>
        <Card className="custom_card">
          <Card.Header className="row d-flex justify-content-start custom_card_header-blk5">
            <div className="col-4 field_name_heading">
              <span className="pending-number">20 :</span>{" "}
              <span className="pending-text">Pending Approvals</span>
            </div>
            <div className="col-4 field_name_heading">
              <span className="pending-number">10 :</span>{" "}
              <span className="pending-text">Approved Allocations</span>
            </div>
            <div className="col-4 d-flex justify-content-end">
              <CustomToggle
                eventKey="0"
                onToggle={handleToggleAccordion1}
                isOpen={openCardsAccordion1.includes("0")}
              ></CustomToggle>
            </div>
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              <div className="filter-section">
                <div style={{ width: "35%" }}>
                  <input
                    placeholder="Search by BU, Vendor, Department .........."
                    style={{
                      width: "95%",
                      padding: "10px",
                      borderRadius: "8px",
                      border: "1px solid #ABA8A8",
                    }}
                  />
                </div>
                <div style={{ width: "15%" }} className="sd-container">
                  <input
                    style={{
                      width: "80%",
                      padding: "6px",
                      color: "gray",
                      borderRadius: "8px",
                      border: "1px solid #ABA8A8",
                    }}
                    type="date"
                    name="selected_date"
                  />
                  <span className="open-button">
                    <img src={Calender} alt="" />
                  </span>
                </div>
                <div style={{ width: "15%" }} className="sd-container">
                  <input
                    placeholder="Select a date"
                    style={{
                      width: "80%",
                      color: "gray",
                      padding: "6px",
                      borderRadius: "8px",
                      border: "1px solid #ABA8A8",
                    }}
                    type="date"
                    name="selected_date"
                  />
                  <span className="open-button">
                    <img src={Calender} alt="" />
                  </span>
                </div>

                <Button
                  style={{
                    background: "#295435",
                    padding: "",
                    borderRadius: "8px",
                    width: "7%",
                    height: "40px",
                    fontFamily: "AvenirMedium",
                  }}
                >
                  Submit
                </Button>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    width: "34%",
                  }}
                >
                  <div>
                    <img
                      src={Reset}
                      alt="reset"
                      style={{
                        width: "15px",
                        height: "15px",
                        marginLeft: "40px",
                      }}
                    />
                  </div>
                  <div
                    style={{
                      width: "10%",
                      textAlign: "center",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    <span
                      style={{
                        textDecoration: "underline",
                        fontFamily: "AvenirMedium",
                      }}
                    >
                      Clear
                    </span>
                  </div>
                </div>
              </div>
              <div style={{ marginTop: "30px" }}>
                <CustomTable data={data} />
              </div>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
      {/* </div> */}
      {/* <div className="bu-title">
        <div style={{ width: "17%" }}>
          <div
            style={{
              display: "flex",
              gap: "10px",
              fontFamily: "Montserrat",
            }}
          >
            <span className="bu-style">BU :</span>
            <span className="bu-style-gf"> GNF</span>
            <p className="bu-change">Change</p>
          </div>
        </div>
        <div style={{ width: "25%" }}>
          <div style={{ display: "flex", gap: "10px" }}>
            <span className="bu-style">Vendor :</span>{" "}
            <span className="bu-style-gf"> BACC - 1112</span>
            <p className="bu-change">Change</p>
          </div>
        </div>
        <div style={{ width: "60%" }}>
          <div>
            <span className="bu-style">YTD :</span>
            <span className="bu-style-gf"> 1,60,000</span>
          </div>
        </div>
        <div
          style={{
            background: "#295435",
            padding: "5px 10px",
            height: "2%",
            borderRadius: "5px",
            marginRight: "8px",
          }}
          onClick={handleDownload}
        >
          <img src={Download} alt="messaged" />
        </div>
        <div
          style={{
            background: "#295435",
            padding: "5px 10px",
            height: "2%",
            borderRadius: "5px",
          }}
        >
          <img src={Shares} alt="messaged" />
        </div>
      </div> */}

      <LogModal
        show={modalShow2}
        hideModal={() => setModalShow2(false)}
        closeButton
        color="#E7FFED"
        radius="10px"
        size="lg"
        onHide={() => setModalShow2(false)}
        title={
          <div>
            <span
              style={{
                fontSize: "18px",
                fontWeight: "900",
                fontFamily: "BloggerBold",
              }}
            >
              Allocation Approved Successfully
            </span>
          </div>
        }
        body={
          <>
            <div className="horizontal-line"></div>
            <div
              style={{
                textAlign: "center",
                padding: "25px 0px 35px 0px",
              }}
            >
              <p style={{ fontFamily: "AvenirLight" }}>
                You have approved successfully the respective allocations
              </p>
            </div>
          </>
        }
      />

      <LogModal
        show={modalShow3}
        hideModal={() => setModalShow3(false)}
        closeButton
        color="#E7FFED"
        radius="10px"
        size="lg"
        onHide={() => setModalShow3(false)}
        title={
          <div>
            <span
              style={{
                fontSize: "18px",
                fontWeight: "900",
                fontFamily: "BloggerBold",
              }}
            >
              Allocation Rejected
            </span>
          </div>
        }
        body={
          <>
            <div className="horizontal-line"></div>
            <div style={{ textAlign: "center", padding: "25px 0px 35px 0px" }}>
              <p style={{ fontFamily: "AvenirLight" }}>
                You have rejected successfully, please add respective comments
                for better visibility
              </p>
            </div>
          </>
        }
      />
      <LogModal
        show={modalShow4}
        hideModal={() => setModalShow4(false)}
        closeButton
        color="#E7FFED"
        radius="10px"
        size="lg"
        onHide={() => setModalShow4(false)}
        title={
          <div>
            <span style={{ fontSize: "18px", fontWeight: "900" }}>
              Downloaded Successfully
            </span>
          </div>
        }
        body={
          <>
            <div className="horizontal-line"></div>
            <div style={{ textAlign: "center", padding: "25px 0px 35px 0px" }}>
              <span>Table data is downloaded as an excel to your device </span>
            </div>
          </>
        }
      />
      <LogModal
        show={modalShow1}
        onHide={() => setModalShow1(false)}
        color="#D9D9D9"
        radius="0px"
        size="lg"
        body={
          <div className="add-message-section">
            <div className="inside-message-section">
              <p className="message-disc">
                Reducedd the allocation to the displays and increase for
                promotions
              </p>
              <div className="messaged-by">
                <p> ~ Kadir : 23rd Dec 2023</p>
              </div>
              <div>
                <textarea
                  rows={4}
                  cols={88}
                  className="text-area-style"
                  placeholder="Add Your Comment ...... "
                />
              </div>
            </div>
            <div>
              <MutiSelect
                placeholder="Select the Users...."
                options={departmentoptions}
              />
            </div>
            <div className="success-btn">
              <Button className="inside-btn" style={{ color: "white" }}>
                Submit
              </Button>
            </div>
          </div>
        }
      />
    </div>
  );
};

export default DashboardTable;

function CustomToggle({ children, eventKey, onToggle }) {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = useAccordionButton(eventKey, () => {
    onToggle(eventKey);
    setIsOpen(!isOpen);
  });

  return (
    <>
      <img
        src={isOpen ? Minus : Plus} // Assuming Minus is your minus icon
        alt={isOpen ? "minus" : "plus"}
        onClick={handleClick}
        width={40}
        height={20}
      ></img>
      <div> {children}</div>
    </>
  );
}
