import React from "react";

const ReviewLogs = () => {
  const stepperStatus = [
    {
      title: "Finance 2",
      status: "In Review",
    },
    {
      title: "Finance 1",
      status: "In Review",
    },
    {
      title: "Director",
      status: "In Review",
    },
    {
      title: "Unit Manager",
      status: "In Review",
    },
    {
      title: "Category Manager ",
      status: "In Review",
    },
    {
      title: "Approver 2",
      status: "In Review",
    },
    {
      title: "Approver 1",
      status: "Barun : 24rth Dec 2023",
    },
    {
      title: "Allocator",
      status: "Kadir : 23rd Dec 2023",
    },
  ];

  return (
    <div style={{ padding: "20px 30px 10px 40px" }}>
      {stepperStatus.map((item, index) => (
        <div key={index} style={{ display: "flex", gap: "20px" }}>
          <div>
            <div
              style={{
                background: "#D9D9D9",
                borderRadius: "50%",
                width: "23px",
                height: "23px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {item.status !== "In Review" ? (
                <div
                  style={{
                    background: "#295435",
                    borderRadius: "50%",
                    width: "13px",
                    height: "13px",
                  }}
                ></div>
              ) : null}
            </div>

            {index !== stepperStatus.length - 1 && ( // Conditionally render the line
              <div
                style={{
                  background: "#D9D9D9",
                  height: "30px",
                  width: "22%",
                  marginLeft: "9px",
                }}
              ></div>
            )}
          </div>
          <div style={{ display: "flex" }}>
            <div style={{ flex: "0 0 130px" }}>
              <p style={{ fontSize: "15px", fontWeight: "800" }}>
                {item.title}
              </p>
            </div>
            <div style={{ flex: "0 0 30px" }}>:</div>
            <div style={{ flex: "0 0 180px" }}>
              <p style={{ fontSize: "15px", fontWeight: "300" }}>
                {item.status === "In Review" ? (
                  <div
                    style={{
                      fontSize: "15px",
                      fontWeight: "800",
                      color: "#295435",
                    }}
                  >
                    {item.status}
                  </div>
                ) : (
                  <div> {item.status}</div>
                )}
              </p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ReviewLogs;
