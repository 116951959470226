import { createSlice } from "@reduxjs/toolkit";

const SwitchButtonReducer = createSlice({
  name: "SwitchButton",
  initialState: {
    isActive: false,
  },

  reducers: {
    clearSwitchStates: (state) => {
      state.isActive = false;
    },
    setSwitchState: (state, action) => {
      state.isActive = true;
    },
  },
  extraReducers: (builder) => {},
});

export default SwitchButtonReducer.reducer;

export const { setSwitchState, clearSwitchStates } =
  SwitchButtonReducer.actions;
