import React, { useEffect, useState } from "react";
import "./margins.css";
import arrow from "../../Images/Group 67.svg";
import down_price from "../../Images/down_price.svg";
import dropdown from "../../Images/drop_indicator.svg";
import progressStop from "../../Images/progressStop.svg";
import progressCircle from "../../Images/progress-bar-circle.svg";
import progressCimg from "../../Images/progress-c-image.png";
import LogModal from "../../UIComponents/Modal/CustomModal";
import { useLocation } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import ProgressBar from "react-bootstrap/ProgressBar";
import MutiSelect from "../../UIComponents/MutliSelect";
import DashboardTable from "../../Pages/DashboardTable";
import AccordionComponent from "./Accordion";
import Select from "react-select";
import ModificationLogs from "../AllModals/ModificationLogs/ModificationLogs";
import { useGetDepartmentQuery } from "../../Redux/services/department/departmentSlice";
import { useGetVendorsQuery } from "../../Redux/services/vendor/vendorSlice";
import { useGetUnitsQuery } from "../../Redux/services/unit/unitSlice";
import Cookies from "js-cookie";
import { useGetSummaryQuery } from "../../Redux/services/summary/summarySlice";
import { useGetDashboardQuery } from "../../Redux/services/dashboard/dashboardSlice";
import DownloadIcon from "../../Images/downloadIcon.png";
import MarginsMessage from "./NotificationMargin";
import currentMonth from "../../Images/currentMonth.png";
import lastMonth from "../../Images/LastMonth.png";
import toBeAllocated from "../../Images/toBeAllocated.png";
const MainMargins = () => {
  const token = Cookies.get("token");
  console.log("token==>", token);
  const [selectedVendorId, setSelectedVendorId] = useState();
  const [selectedMonth, setSelectedMonth] = useState();

  const { data: getDepartmentDetails } = useGetDepartmentQuery(token);
  const { data: getVendorDetails } = useGetVendorsQuery(token);
  const { data: getUnitDetails } = useGetUnitsQuery(token);
  const { data: getSummary } = useGetSummaryQuery({
    token,
    selectedVendorId,
    selectedMonth,
  });
  const { data: getDashboardDetails } = useGetDashboardQuery({
    token,
    selectedVendorId,
    selectedMonth,
  });
  console.log("getDashboardDetails", getDashboardDetails);

  const amount = getDashboardDetails?.[0]?.amount;

  console.log("getSummary", getSummary);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const butable = searchParams.get("butable");

  const queryParams = new URLSearchParams(window.location.search);

  const deptValue = queryParams.get("dept");
  const vendValue = queryParams.get("vend");

  console.log("vendValue=>", vendValue);

  const [selectedMonthLable, setSelectedMonthLable] = useState();

  const [selectedVendor, setSelectedVendor] = useState();
  const [selectedDepartment, setSelectedDepartment] = useState([]);

  console.log("selectedMonth", selectedMonth);

  const [selectedBu, setSelectedBu] = useState();

  const [modalShow, setModalShow] = useState(false);
  const [modalShow1, setModalShow1] = useState(false);
  const [modalShow2, setModalShow2] = useState(false);

  useEffect(() => {
    if (deptValue) {
      setSelectedDepartment(deptValue);
      setSelectedVendor(vendValue);
    }
  }, [deptValue, vendValue]);

  const handleVendorSelect = (vendor) => {
    setSelectedVendor(vendor.label);
    setSelectedVendorId(vendor.value);
  };
  const handleBuSelect = (bu) => {
    setSelectedBu(bu.label);
  };
  const handleDepartmentSelect = (depart) => {
    setSelectedDepartment(depart.label);
  };
  const handleMonthSelect = (month) => {
    console.log("month", month);
    setSelectedMonthLable(month?.label);
    setSelectedMonth(month?.value);
  };

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const MonthNameOption = monthNames.map((month, index) => ({
    value: index + 1,
    label: month,
  }));

  const salaryData = [
    15000, 190000, 1500, 2500, 110000, 160000, 2200, 16000, 120000, 210000,
    1600000, 99900,
  ];

  var progressData = 65;

  const departmentOptionss =
    getDepartmentDetails?.map((item) => ({
      label: item.deptName ?? "",
      value: item.deptName ?? "",
    })) || [];

  // const unitOptions =
  // getUnitDetails?.map((item) => ({
  //   label: item.groupName ?? "",
  //   value: item.groupNo ?? "",
  // })) || [];

  const filterUnit = getVendorDetails?.filter(
    (unitItem) =>
      `${unitItem.legalBdaNumber ?? ""}-${unitItem?.supName ?? ""}` ===
      vendValue
  );

  const unitOptions =
    filterUnit && filterUnit.length
      ? filterUnit?.map((item) => ({
          label: item.commercialBu ?? "",
          value: item.commercialBu ?? "",
        }))
      : getUnitDetails?.map((item) => ({
          label: item.groupName ?? "",
          value: item.groupNo ?? "",
        }));

  console.log("filterUnit", filterUnit?.[0]);

  const filteredVendor = getVendorDetails?.filter(
    (vendorItem) => vendorItem?.commercialBu === selectedBu
  );

  // const vendorOptions =
  //   fiteredVender?.map((item) => ({
  //     label: `${item.legalBdaNumber ?? ""}-${item.supName ?? ""}`,
  //     value: item.supplierId ?? "",
  //   })) || [];
  const vendorOptions =
    filteredVendor && filteredVendor.length
      ? filteredVendor?.map((item) => ({
          label: `${item.legalBdaNumber ?? ""}-${item?.supName ?? ""}`,
          value: item.supplierSite ?? "",
        }))
      : getVendorDetails?.map((item) => ({
          label: `${item.legalBdaNumber ?? ""}-${item?.supName ?? ""}`,
          value: item?.supplierSite ?? "",
        }));

  const departmentoptions = [
    { value: "Mobiles", label: "Mobiles" },
    {
      value: "Cards & Communications Trading",
      label: "Cards & Communications Trading",
    },
    { value: "Meat", label: "Meat" },
    { value: "Poultry", label: "Poultry" },
    { value: "Fishery", label: "Fishery" },
  ];
  const CustomDownArrow = () => (
    <div>
      <img src={dropdown} alt="arrow" />
    </div>
  );

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      outline: "none",
      boxShadow: "none",
      border: "none",
      transition: "border-color 0.2s", //
      "&:hover": {
        borderColor: "#00000",
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#000000",
      fontWeight: 400,
      fontFamily: "AvenirMedium",
    }),
  };
  const customComponents = {
    Option: ({ innerProps, label }) => (
      <div
        {...innerProps}
        className="select_option"
        style={{
          fontFamily: "AvenirMedium",
        }}
      >
        {label}
      </div>
    ),
    DropdownIndicator: CustomDownArrow,
  };
  const monthStyles = {
    control: (provided, state) => ({
      ...provided,
      outline: "none",
      boxShadow: "none",
      border: "none",
      // borderBottom: "1px solid #ccc",
      borderRadius: "0px",
      fontFamily: "AvenirMedium",
      borderTopColor: "#5ABD76",
      backgroundColor: "transparent",
      borderBottom: "1px solid #000000 !important",
      transition: "border-color 0.2s", //
      "&:hover": {
        borderColor: "#00000",
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#000000",
      fontWeight: 400,
      fontFamily: "AvenirMedium",
    }),
  };
  const monthComponents = {
    Option: ({ innerProps, label }) => (
      <div
        {...innerProps}
        className="select_option_month"
        style={{
          fontFamily: "AvenirMedium",
        }}
      >
        {label}
      </div>
    ),
    DropdownIndicator: CustomDownArrow,
  };
  return (
    <>
      <MarginsMessage />
      <div className="main_container_margin">
        <div className="main_section">
          <div className="select_box">
            <div class="row">
              <div class="col-3">
                <div class="custom-select-wrapper">
                  <Select
                    value={selectedBu?.label}
                    onChange={handleBuSelect}
                    options={unitOptions}
                    className=""
                    styles={{ ...customStyles }}
                    classNamePrefix=""
                    components={customComponents}
                    placeholder="Select Business Unit....."
                  />
                </div>
              </div>
              <div className="col-3">
                <div class="custom-select-wrapper">
                  <Select
                    value={selectedVendor?.label}
                    defaultValue={{
                      label: vendValue || "Select Vendor.....",
                      value: vendValue,
                    }}
                    onChange={(e) => handleVendorSelect(e)}
                    // options={vendoroptions}
                    options={vendorOptions}
                    className=""
                    styles={{ ...customStyles }}
                    classNamePrefix=""
                    components={customComponents}
                    placeholder="Select Vendor....."
                  />
                </div>
              </div>
              <div className="col-3">
                <div class="custom-select-wrapper">
                  <Select
                    value={selectedDepartment?.label}
                    defaultValue={{
                      label: deptValue || "Selectd Department.....",
                      value: deptValue,
                    }}
                    onChange={handleDepartmentSelect}
                    options={departmentOptionss}
                    className=""
                    styles={{ ...customStyles }}
                    classNamePrefix=""
                    components={customComponents}
                    placeholder="Selectd Department....."
                  />
                </div>
              </div>
              <div className="col-3">
                <div class="custom-select-wrapper">
                  <Select
                    value={selectedMonth?.label}
                    onChange={handleMonthSelect}
                    options={MonthNameOption}
                    className="month_select"
                    styles={{ ...monthStyles }}
                    classNamePrefix=""
                    components={monthComponents}
                    placeholder="Select Month"
                  />
                </div>
              </div>
            </div>
          </div>

          {/* <div className="selected_value_section">
            <div className="row">
              <div className="col-3">
                <div>
                  <div className="selected_box_value">
                    <p class="selcted_text">BUSINESS UNIT:</p>
                    <p class="large_value">&nbsp;{selectedBu}</p>
                  </div>
                </div>
              </div>
              <div className="col-3">
                <div>
                  <div className="selected_box_value">
                    <p className="selcted_text">VENDOR :</p>
                    <p className="large_value">&nbsp;{selectedVendor}</p>
                  </div>
                </div>
              </div>
              <div className="col-3">
                <div>
                  <div className="selected_box_value">
                    <p className="selcted_text">DEPARTMENT :</p>
                    <p className=" large_value ">
                      <span>&nbsp;{selectedDepartment}</span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-1">
                <div>
                  <div className="selected_box_value">
                    <p
                      className="selcted_text text_clear"
                      onClick={(e) => {
                        setSelectedVendor();
                        setSelectedBu();
                        setSelectedDepartment();
                      }}
                    >
                      X Clear
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-2">
                <div className="selected_box_value">
                  <div className="selcted_text month_selected ">
                    <div className="custom-select-wrapper">
                      <Select
                        value={selectedMonth?.label}
                        onChange={handleMonthSelect}
                        options={MonthNameOption}
                        className="month_select"
                        styles={{ ...monthStyles }}
                        classNamePrefix=""
                        components={monthComponents}
                        placeholder="Select Month"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          {/* <hr></hr> */}
          {/* <div className="price_section">
            <div className="row  ">
              <div className="col-md-12">
                <div className="price_box ">
                  <div className="row price_center_box">
                    <div className="col-md-2">
                      <div className="card p-0 bg-transparent border-0">
                        <div className="card-body text-center">
                          <h5 className="font-weight-bold price_meter">
                            {getSummary?.currentMonth?.toFixed(2)}
                          </h5>
                          <p className="font-weight-bold">Current Month</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="card p-0 bg-transparent border-0">
                        <div className="card-body text-center">
                          <h5 className="font-weight-bold price_meter">
                            {getSummary?.lastMonthBalance?.toFixed(2)}
                          </h5>
                          <p className="font-weight-bold">Last Month Balance</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="card p-0 bg-transparent border-0">
                        <div className="card-body text-center">
                          <h5 className="font-weight-bold price_meter">
                            {getSummary?.toBeAllocated?.toFixed(2)}
                          </h5>
                          <p className="font-weight-bold">To Be Allocated</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="card p-0 bg-transparent border-0">
                        <div className="card-body text-center">
                          <h5 className="font-weight-bold price_meter">
                            {getSummary?.balance?.toFixed(2)}
                          </h5>
                          <p className="font-weight-bold">Balance</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="card p-0 bg-transparent border-0">
                        <div className="card-body text-center">
                          <h5 className="font-weight-bold price_meter">
                            {getSummary?.ytd?.toFixed(2)}
                          </h5>
                          <p className="font-weight-bold">YTD</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="card p-0 bg-transparent border-0">
                        <div className="card-body text-center">
                          <h5 className="font-weight-bold price_meter">
                            {getSummary?.lp?.toFixed(2)}
                          </h5> */}
          {/* <p className="font-weight-bold">Lump Sum Amount</p> */}
          {/* <p className="font-weight-bold">Remaining Lump Sum</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr></hr> */}

          <div className="money-main-container">
            <div className="money-sub-container">
              <div className="money-icon-container">
                <img alt="" src={currentMonth} />
              </div>
              <div className="money-content-container">
                <span className="money-name">Current Month</span>
                <span className="money-numbers">
                  {getSummary?.currentMonth?.toFixed(2)}SR
                </span>
              </div>
            </div>

            <div className="money-sub-container">
              <div className="money-icon-container">
                <img alt="" src={lastMonth} />
              </div>
              <div className="money-content-container">
                <span className="money-name">Last Month Balance</span>
                <span className="money-numbers">
                  {getSummary?.toBeAllocated?.toFixed(2)}SR
                </span>
              </div>
            </div>

            <div className="money-sub-container">
              <div className="money-icon-container">
                <img alt="" src={toBeAllocated} />
              </div>
              <div className="money-content-container">
                <span className="money-name">To Be Allocated</span>
                <span className="money-numbers">
                  {getSummary?.toBeAllocated?.toFixed(2)}SR
                </span>
              </div>
            </div>

            <div className="money-sub-container">
              <div className="money-icon-container">
                <img alt="" src={currentMonth} />
              </div>
              <div className="money-content-container">
                <span className="money-name">Balance</span>
                <span className="money-numbers">
                  {getSummary?.balance?.toFixed(2)}SR
                </span>
              </div>
            </div>

            <div className="money-sub-container">
              <div className="money-icon-container">
                <img alt="" src={lastMonth} />
              </div>
              <div className="money-content-container">
                <span className="money-name">YTD</span>
                <span className="money-numbers">
                  {getSummary?.ytd?.toFixed(2)}SR
                </span>
              </div>
            </div>

            <div className="money-sub-container">
              <div className="money-icon-container">
                <img alt="" src={lastMonth} />
              </div>
              <div className="money-content-container">
                <span className="money-name">Remaining Lump sum</span>
                <span className="money-numbers">
                  {getSummary?.lp?.toFixed(2)}SR
                </span>
              </div>
            </div>
          </div>

          <div className="month_by_price">
            <div className="progress_bar">
              <div>
                {selectedMonth !== null && (
                  <>
                    <div
                      className="month_display_text"
                      style={{ margin: "0px 5px" }}
                    >
                      {Array.from({ length: selectedMonth }, (_, index) => (
                        <>
                          <div key={index} style={{ margin: "0px 1.50rem" }}>
                            <p className="selected_month">
                              {/* M{index + 1}- {salaryData[index]} */}M
                              {index + 1}
                            </p>
                          </div>
                          <div>
                            <img
                              src={arrow} // Assuming arrow is your arrow icon
                              alt={"plus"}
                              width={15}
                            ></img>
                            <div>
                              <img
                                src={progressCimg} // Assuming progressCimg is your progressCimg icon
                                alt={"progressCimg"}
                                width={25}
                              ></img>
                            </div>
                          </div>
                        </>
                      ))}
                    </div>
                  </>
                )}
              </div>
              <ProgressBar
                now={progressData}
                label={
                  <>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "end",
                      }}
                    >
                      <p style={{ paddingRight: "20px", marginBottom: "0px" }}>
                        Annual Allocation Amount
                      </p>
                      <div style={{ paddingRight: "10px" }}>
                        <img src={progressStop} alt="progressdata"></img>
                      </div>
                    </div>
                  </>
                }
                className="progressbar_inner"
              />
              <div className="d-flex justify-content-end">
                <div
                  style={{
                    position: "absolute",
                    bottom: "50px",
                    right: "10px",
                  }}
                >
                  <img
                    src={progressCircle} // Assuming Minus is your minus icon
                    alt={"progressCircle"}
                    // width={15}
                  ></img>
                </div>
                <p>{amount}</p>
                <div>
                  <img
                    src={down_price} // Assuming Minus is your minus icon
                    alt={"down_price"}
                    width={15}
                  ></img>
                </div>
              </div>
            </div>
          </div>

          <div className="accordion_main_section">
            {butable !== "bu-table" ? (
              <>
                <DashboardTable />
                <div className="acc-bg">
                  <Accordion>
                    <Card className="custom_card">
                      {/* <Card.Header className="row custom_card_header">
                        <div className="col-2 field_name_heading">
                          <p> BU :</p>
                          <span className="field_value span14">&nbsp; GNF</span>
                          &nbsp; &nbsp; &nbsp;
                          <span className="field_change">change</span>
                        </div>
                        <div className="col-2 field_name">
                          <p> VENDOR : </p>
                          <span className="field_value span14 elips">
                            &nbsp; {selectedVendor} 
                          </span>
                          &nbsp; &nbsp;
                          <span className="field_change">change</span>
                        </div>
                        <div className="col-2 field_name">
                          YTD :{" "}
                          <span className="field_value span14">
                            &nbsp;16,000,000
                          </span>
                        </div>

                        <div className="col-2">
                          <div>
                            <div className="custom-select-wrapper">
                              <Select
                                value={selectedMonth?.label}
                                onChange={handleMonthSelect}
                                options={MonthNameOption}
                                className="month_select"
                                styles={{ ...monthStyles }}
                                classNamePrefix=""
                                components={monthComponents}
                                placeholder="Select Month"
                              />
                            </div>
                          </div>
                        </div>
                      </Card.Header> */}
                      {/* <Card.Header className="row custom_card_header">
                        <div className="col-2 field_name_heading">
                          <p> BU :</p>
                          <span className="field_value span14 elips">
                            &nbsp; {selectedBu}
                          </span>
                          &nbsp; &nbsp; &nbsp;
                          <span className="field_change">change</span>
                        </div>

                        <div className="col-2 field_name">
                          <p> VENDOR : </p>
                          <span className="field_value span14 elips">
                            &nbsp; {selectedVendor}
                          </span>
                          &nbsp; &nbsp;
                          <span className="field_change">change</span>
                        </div>

                        <div className="col-2 field_name">
                          YTD :{" "}
                          <span className="field_value span14">
                            &nbsp;16,000,000
                          </span>
                        </div>

                        <div className="col-2 field_name">
                          <div className="col-2">
                            <div>
                              <div className="custom-select-wrapper">
                                <Select
                                  value={selectedMonth?.label}
                                  onChange={handleMonthSelect}
                                  options={MonthNameOption}
                                  className="month_select"
                                  styles={{ ...monthStyles }}
                                  classNamePrefix=""
                                  components={monthComponents}
                                  placeholder="Select Month"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </Card.Header> */}
                    </Card>
                  </Accordion>
                </div>
                <Accordion>
                  <Card className="custom_card">
                    <div className="custom_card_header-blk44">
                      <div className="custom-card-content">
                        <div>
                          <p>
                            <b>Month</b>
                          </p>
                          <p className="analytics-span1">
                            {selectedMonthLable}
                          </p>
                        </div>
                        <div>
                          <p>
                            <b>Current Month Balance</b>
                          </p>
                          <p className="analytics-span1">
                            {getSummary?.currentMonth
                              ? `${getSummary?.currentMonth?.toFixed(2)}SR`
                              : ""}
                          </p>
                        </div>
                        <div>
                          <p>
                            <b>Last Month Balance </b>
                          </p>
                          <p className="analytics-span1">
                            {" "}
                            {getSummary?.toBeAllocated
                              ? `${getSummary?.toBeAllocated?.toFixed(2)}SR`
                              : ""}
                          </p>
                        </div>
                        <div>
                          <p>
                            <b>To Be Allocated</b>
                          </p>
                          <p className="analytics-span1">
                            {" "}
                            {getSummary?.toBeAllocated
                              ? `${getSummary?.toBeAllocated?.toFixed(2)}SR`
                              : ""}
                          </p>
                        </div>
                        <div>
                          <p>
                            <b>Balance</b>
                          </p>
                          <p className="analytics-span1">
                            {getSummary?.balance
                              ? `${getSummary?.balance?.toFixed(2)}SR`
                              : ""}
                          </p>
                        </div>
                        <div>
                          <img src={DownloadIcon} alt="DownloadIcon" />
                        </div>
                      </div>
                    </div>
                  </Card>
                </Accordion>
                <AccordionComponent
                  vendorId={selectedVendorId}
                  // vendorId="315214"
                  selectedMonth={selectedMonth}
                  // selectedMonth="10"
                />
              </>
            ) : (
              <>
                <DashboardTable />
                <hr className="hr_below_table"></hr>
                <div className="acc-bg">
                  <Accordion>
                    <Card className="custom_card">
                      <Card.Header className="row custom_card_header">
                        <div className="col-2 field_name_heading">
                          <p> BU :</p>
                          <span className="field_value span14">&nbsp; GNF</span>
                          &nbsp; &nbsp; &nbsp;
                          <span className="field_change">change</span>
                        </div>
                        <div className="col-2 field_name">
                          <p> VENDOR : </p>
                          <span className="field_value span14">
                            &nbsp; VENDOR
                          </span>
                          &nbsp; &nbsp;
                          <span className="field_change">change</span>
                        </div>
                        <div className="col-2 field_name">
                          YTD :{" "}
                          <span className="field_value span14">
                            &nbsp;16,000,000
                          </span>
                        </div>

                        <div className="col-2">
                          <div>
                            <div className="custom-select-wrapper">
                              <Select
                                value={selectedMonth?.label}
                                onChange={handleMonthSelect}
                                options={MonthNameOption}
                                className="month_select"
                                styles={{ ...monthStyles }}
                                classNamePrefix=""
                                components={monthComponents}
                                placeholder="Select Month"
                              />
                            </div>
                          </div>
                        </div>
                      </Card.Header>
                    </Card>
                  </Accordion>
                </div>
                <Accordion>
                  <Card className="custom_card">
                    <div className="custom_card_header-blk1">
                      <div className="custom-card-content">
                        <p>
                          <b>Month</b>
                        </p>
                        <p className="analytics-span1">Aug 08</p>
                      </div>

                      <div className="col-2 field_name">
                        Current Monthdd :
                        <span className="span14">&nbsp; 10003</span>
                        <div className="custom-card-content">
                          <p>
                            <b>Current Month </b>
                          </p>
                          <p className="analytics-span1">1000</p>
                        </div>
                        <div className="custom-card-content">
                          <p>
                            <b>Last Month Balance </b>
                          </p>
                          <p className="analytics-span1">450000</p>
                        </div>
                        <div className="custom-card-content">
                          <p>
                            <b>To Be Allocated </b>
                          </p>
                          <p className="analytics-span1">5000</p>
                        </div>
                        <div className="custom-card-content">
                          <p>
                            <b>Balance</b>
                          </p>
                          <p className="analytics-span1">9000</p>
                        </div>
                        <div>
                          <img src={DownloadIcon} alt="DownloadIcon" />
                        </div>
                      </div>
                    </div>
                  </Card>
                </Accordion>

                {/* <AccordionComponent /> */}
              </>
            )}
          </div>
        </div>
      </div>
      <LogModal
        show={modalShow1}
        onHide={() => setModalShow1(false)}
        color="#D9D9D9"
        size="lg"
        body={
          <div className="add-message-section">
            <div className="inside-message-section">
              <p className="message-disc">
                Reduce the allocation to the displays and increase for
                promotions
              </p>
              <div className="messaged-by">
                <span> ~ Kadir : 23rd Dec 2023</span>
              </div>
              <div>
                <textarea
                  rows={4}
                  cols={88}
                  className="text-area-style"
                  placeholder="Add Your Comment ...... "
                />
              </div>
            </div>
            <div>
              <MutiSelect
                placeholder="Select the Users...."
                options={departmentoptions}
              />
            </div>
            <div className="success-btn">
              <Button className="inside-btn" style={{ color: "white" }}>
                Submit
              </Button>
            </div>
          </div>
        }
      />
      <LogModal
        show={modalShow}
        hideModal={() => setModalShow(false)}
        closeButton
        color="#E7FFED"
        radius="10px"
        size="lg"
        onHide={() => setModalShow(false)}
        title={
          <div>
            <span style={{ fontSize: "18px", fontWeight: "900" }}>
              Modification logs
            </span>
          </div>
        }
        body={
          <>
            <div className="horizontal-line"></div>
            <ModificationLogs />
          </>
        }
      />
      <LogModal
        show={modalShow2}
        color="#E7FFED"
        radius="5px"
        onHide={() => setModalShow2(false)}
        body={
          <>
            <div style={{ display: "flex" }}>
              <div style={{ width: "80%" }}>
                <p className="allow-success" style={{ textAlign: "end" }}>
                  Allocated Successfully
                </p>
              </div>
              <div
                style={{ width: "50%" }}
                onClick={() => setModalShow2(false)}
              >
                <p style={{ textAlign: "end" }}>X</p>
              </div>
            </div>
            <hr />
            <div style={{ textAlign: "center" }}>
              <p className="allow-success-disc">
                You have allocated successfully, please request the respective
                approver to approve before the month closer
              </p>
              <p className="allow-successdisc">
                We have already notified him over email and notifications on the
                same.{" "}
              </p>
            </div>
          </>
        }
      />
    </>
  );
};

export default MainMargins;
