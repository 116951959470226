import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import "./Login.css";
import logo_login from "../../Images/Logo-Etqan-RGB.svg";
import { useDispatch } from "react-redux";

import { useNavigate } from "react-router";
import { NavLink } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { setSwitchState } from "../../Redux/slices/switchButton";
import { usePostLoginMutation } from "../../Redux/services/auth/loginSlice";
import Cookies from "js-cookie";
import { notification } from "../../UIComponents/Notifications/notification";
import "react-toastify/dist/ReactToastify.css";

const Login = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [loginMutation, result] = usePostLoginMutation();

  console.log("result", result);

  // const [loading, setloading] = useState(true);

  const submitHandler = async (e) => {
    e.preventDefault();
    dispatch(setSwitchState());
    try {
      await loginMutation({
        username: email,
        password: password,
      }).then((res) => {
        console.log("error==>", res, res?.error?.data?.errors[0]?.message);

        Cookies.set("token", res?.data?.token);
        if (res?.error?.data?.status === "ERROR") {
          notification.error({
            message: res?.error?.data?.errors[0]?.message,
            position: "top-right",
          });
        } else {
          notification.success({
            message: "Login successful",
            position: "top-right",
          });
          navigate("/dashboard");
        }
      });
    } catch (error) {
      toast("Please enter valid user and password");
    }
  };

  const queryParams = new URLSearchParams();
  queryParams.set("butable", "bu-table");

  const hanndleEmail = (e) => {
    const { value } = e.target;
    setEmail(value);
  };
  const handlePassword = (e) => {
    const { value } = e.target;
    setPassword(value);
  };

  const isLoginDisable = !(email && password);

  return (
    <>
      <div className="mainBGContainer">
        <div className="row">
          <div className="col-5">
            <div className="logo_image_left">
              <img
                //className="px-2 "
                src={logo_login}
                alt="edit"
                width={410}
                height={180}
              />
            </div>
          </div>
          <div className="col-7">
            <div className="LoginContainer2"></div>
            <div className="LoginContainer">
              <div className="Login_right_image">
                <div className="Login">
                  <div className="Card">
                    <form>
                      <h3 className="header">Login</h3>
                      <br />
                      <div className="form-group login_input">
                        <div className="icon-input-email">
                          <input
                            type="text"
                            className="form-control"
                            id="text"
                            placeholder="Email ID"
                            // defaultValue="retails"
                            onChange={(e) => hanndleEmail(e)}
                          />
                        </div>
                      </div>

                      <div className="form-group login_input">
                        <div className="icon-input">
                          <input
                            type="password"
                            className="form-control"
                            id="password"
                            placeholder="Password"
                            onChange={(e) => handlePassword(e)}
                          />
                        </div>
                      </div>
                      <div className="login_btn">
                        <Button
                          block
                          size="lg"
                          type="submit"
                          id="login_btn"
                          onClick={submitHandler}
                          disabled={isLoginDisable}
                        >
                          Login
                        </Button>
                      </div>

                      {/* <div className="or">or</div> */}
                      <div className="or_break_hr">
                        <div className="row">
                          <div className="col-5">
                            <hr className="hr_or"></hr>
                          </div>
                          <div className="col-2">
                            <div className="or">or</div>
                          </div>
                          <div className="col-5">
                            <hr className="hr_or"></hr>
                          </div>
                        </div>
                      </div>
                      <div className="azure_btn">
                        {/* <NavLink
                          to={`/dashboard?${queryParams.toString()}`}
                          className="menu_nav"
                        > */}
                        <Button
                          block
                          size="lg"
                          type="submit"
                          id="azure_btn"
                          disabled
                        >
                          Azure AD
                        </Button>
                        {/* </NavLink> */}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    </>
  );
};

export default Login;
