import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
// import Cookies from "js-cookie";

const clientApi = createApi({
  reducerPath: "clientApi",
  tagTypes: ["getAllowcation", "getApprovals", "getComment"],
  baseQuery: fetchBaseQuery({
    baseUrl: "http://10.160.62.93/api/v1",
    mode: "cors",
    // headers: {
    //   Authorization: `Bearer ${Cookies.get("token")}`,
    // },
  }),

  endpoints: () => ({}),
});

export default clientApi;
