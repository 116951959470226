import clientApi from "../../api/client";

const dashboardApiSlice = clientApi.injectEndpoints({
  endpoints: (builder) => ({
    getDashboard: builder.query({
      query: ({ token, selectedVendorId, selectedMonth }) => {
        return {
          url: `/dashboards/monthly?supplier=${String(
            selectedVendorId
          )}&year=2023&month=${String(selectedMonth)}`,
          //   url: `/dashboards/monthly?supplier=315214&year=2023&month=10`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
      },
    }),
  }),
});

export const { useGetDashboardQuery } = dashboardApiSlice;
export const { getDashboard } = dashboardApiSlice.endpoints;
