import React, { useState } from "react";
import "./NavBar.css";
import Navbar from "react-bootstrap/Navbar";
import pandaMenu from "../../../src/assets/icons/menu.svg";
import Button from "react-bootstrap/Button";
import LogModal from "../../UIComponents/Modal/CustomModal";
import { useGetProfileQuery } from "../../Redux/services/profile/profileSlice";
import { useGetDepartmentQuery } from "../../Redux/services/department/departmentSlice";
import { useGetVendorsQuery } from "../../Redux/services/vendor/vendorSlice";
import Cookies from "js-cookie";
import SingleSelect from "../../UIComponents/SingleSelect/SingleSelect";

import { useProSidebar } from "react-pro-sidebar";
import NotificationIcon from "../../../src/assets/icons/bell.svg";
const NavBar = () => {
  const token = Cookies.get("token");

  const { collapseSidebar, collapsed } = useProSidebar();
  const { data: getDepartmentDetails } = useGetDepartmentQuery(token);
  const { data: getVendorDetails } = useGetVendorsQuery(token);
  const { data: getProfileDetails } = useGetProfileQuery(token);
  const [selectedDepartment, setSelectedDepartment] = useState();
  const [selectedVendor, setSelectedVendor] = useState();
  const [selectedDC, setSelectedDC] = useState("");
  const [loadingOptions, setloadingOptions] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [modalShow3, setModalShow3] = useState(false);

  const handleDropdownChange = (selectedValue) => {};

  const departmentOptionss =
    getDepartmentDetails?.map((item) => ({
      label: item.deptName ?? "",
      value: item.dept ?? "",
    })) || [];

  const vendorOptions =
    getVendorDetails?.map((item) => ({
      label: `${item.legalBdaNumber ?? ""}-${item.supName ?? ""}`,
      value: item.supplierId ?? "",
    })) || [];

  const handleDepartmentSelect = (Depart) => {
    console.log("Depart", Depart);
    setSelectedDepartment(Depart.label);
  };

  const handleVendorSelect = (vendor) => {
    setSelectedVendor(vendor.label);
  };

  const handleSubmit = () => {
    if (selectedDepartment && selectedVendor) {
      setModalShow3(false);
    }
  };

  return (
    <div className="navheader-container w-100 ">
      <Navbar className="sticky-top navbar-container ">
        <div className="nav-header-logo">
          {/* <img className="nav-logo" src={Logo} alt="panda" /> */}
        </div>
        <Navbar.Brand>
          {/* <img
            src={pandaMenu}
            onClick={() => collapseSidebar()}
            className="nav-header-menu"
            alt="panda-menu"
          /> */}
        </Navbar.Brand>
        <div className="header-profile-section">
          <div>
            <span className="notification-icon">
              {" "}
              <img
                src={NotificationIcon}
                alt="panda-notif"
                width={"45px"}
                height={"45px"}
              />
            </span>
          </div>
          <div className="navbar-image-sec">
            <div className="manager-image-container">
              {/* <img src={User} /> */}
              <p className="profile-letter" onClick={() => setModalShow3(true)}>
                {getProfileDetails?.name
                  .match(/(?<=(\s|^))[a-z]/gi)
                  .join("")
                  .toUpperCase()}
              </p>
            </div>
            <div className="navbar-profile-sec"></div>
          </div>
        </div>
      </Navbar>

      <LogModal
        show={modalShow3}
        hideModal={() => setModalShow3(false)}
        closeButton={false}
        color="#E7FFED"
        radius="10px"
        size="lg"
        onHide={() => setModalShow3(false)}
        title={
          <div className="profile-and-setting">
            <span>Profile & Settings</span>
          </div>
        }
        body={
          <>
            <div className="horizontal-line"></div>
            <div>
              <p className="profile-detail-text">Profile Details : </p>
              <div style={{ display: "flex", gap: "40px" }}>
                <div>
                  <input
                    className="profile-input"
                    type="text"
                    name="myInput"
                    // placeholder="First Name"
                    readOnly
                    defaultValue={getProfileDetails?.name}
                  />
                </div>
                <div>
                  <input
                    className="profile-input"
                    type="text"
                    name="myInput"
                    // placeholder="Last Name"
                    readOnly
                    defaultValue={getProfileDetails?.surname}
                  />
                </div>
                <div>
                  <input
                    style={{
                      width: "150%",
                    }}
                    className="profile-input"
                    type="text"
                    name="myInput"
                    // placeholder="Email"
                    readOnly
                    defaultValue={getProfileDetails?.email}
                  />
                </div>
              </div>
              <hr className="dashed-2" />
              <div className="system-setting-section">
                <p className="system-setting-text">System Settings : </p>
                <div style={{ display: "flex", gap: "12%" }}>
                  <div className="muti-select-section">
                    {/* <MutiSelect
                      placeholder="Select Vendors....."
                      options={vendorOptions}
                    /> */}
                    <SingleSelect
                      placeholder="Select Vendors....."
                      options={vendorOptions}
                      handleChange={handleVendorSelect}
                      value={selectedVendor?.label}
                      width={300}
                    />
                  </div>
                  <div className="muti-select-section">
                    {/* <MutiSelect
                      placeholder="Select Departments......"
                      options={departmentOptionss}
                    /> */}
                    <SingleSelect
                      placeholder="Select Departments......"
                      options={departmentOptionss}
                      handleChange={handleDepartmentSelect}
                      value={selectedDepartment?.label}
                      width={300}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="success-btn d-flex justify-content-end">
              <Button
                type="button"
                style={{
                  background: "#295435",
                  width: "15%",
                  borderRadius: "10px",
                  fontFamily: "AvenirMedium",
                }}
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </div>
          </>
        }
      />
    </div>
  );
};

export default NavBar;
