// import React from "react";
// import { useTable, useSortBy } from "react-table";
import React, { useState, useMemo } from "react";
import MutiSelect from "../../UIComponents/MutliSelect";
import LogModal from "../../UIComponents/Modal/CustomModal";
import Messaged from "../../Images/svg/speech-bubble.svg";
import Button from "react-bootstrap/Button";
import { useSortBy, useTable } from "react-table";

import sortdown from "../../Images/svg/sort-down.svg";
import Checked from "../../Images/svg/checked.svg";
import Delete from "../../Images/svg/delete.svg";
import { usePostRejectMutation } from "../../Redux/services/allocations/reject";
import { usePostApprovalsMutation } from "../../Redux/services/allocations/approvals";
import { usePostCommentMutation } from "../../Redux/services/comment/comment";
import { notification } from "../../UIComponents/Notifications/notification";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

const departmentoptions = [
  { value: "Mobiles", label: "Mobiles" },
  {
    value: "Cards & Communications Trading",
    label: "Cards & Communications Trading",
  },
  { value: "Meat", label: "Meat" },
  { value: "Poultry", label: "Poultry" },
  { value: "Fishery", label: "Fishery" },
];

const CustomTable = ({ data }) => {
  console.log("datas=>", data.length ? "kk" : "ll");
  const [postRejectMutation] = usePostRejectMutation();
  const [postApprovals] = usePostApprovalsMutation();
  const [postMessage] = usePostCommentMutation();

  const [modalShow2, setModalShow2] = useState(false);
  const [modalShow3, setModalShow3] = useState(false);
  const [modalShow1, setModalShow1] = useState(false);
  const [comment, setComment] = useState();
  const [calculationId, setCalculationId] = useState();
  const [outputId, setOutputId] = useState();

  const isDisabledSubmit = !comment;

  const handleSubmitModal = (calculationId) => {
    postApprovals([
      {
        calculationId,
        comment: "",
      },
    ]).then((res) => {
      if (res?.error?.data?.status === "ERROR") {
        console.log("sdfsf");
        notification.error({
          message: "Approvals Rejected",
          position: "top-right",
        });
      } else {
        notification.success({
          message: "Approvals successful",
          position: "top-right",
        });
      }
    });
    setModalShow2(true);
  };
  const handleRejectModal = (calculationId) => {
    postRejectMutation([
      {
        calculationId: calculationId,
        comment: "",
      },
    ]).then((res) => {
      if (res?.error?.data?.status === "ERROR") {
        notification.error({
          message: "Reject Unsuccessful",
          position: "top-right",
        });
      } else {
        notification.success({
          message: "Reject Successful",
          position: "top-right",
        });
      }
    });
    // setModalShow3(true);
    // setModalShow2(false);
  };
  const handleMessage = (calculationId, outputId) => {
    console.log("calculationId", calculationId, outputId);
    setCalculationId(calculationId);
    setOutputId(outputId);

    setModalShow1(true);
  };
  const handletextArea = (e) => {
    const { value } = e.target;
    console.log("value", value);
    setComment(value);
  };
  const hnadleSubmit = () => {
    postMessage({
      comment,
      calculationId,
      outputId,
    }).then((res) => {
      if (res?.error?.data?.status === "ERROR") {
        notification.error({
          message: "Please enter comment",
          position: "top-right",
        });
      } else {
        notification.success({
          message: "Comment Added Successful",
          position: "top-right",
        });
        setModalShow1(false);
      }
    });
  };
  const columns = useMemo(
    () => [
      {
        Header: (
          <div>
            <input type="checkbox" />
          </div>
        ),
        accessor: "e",
        disableSortBy: false,
      },
      {
        Header: <span className="header-style">Sno</span>,
        accessor: "outputId",
      },
      {
        Header: <span className="header-style">BU</span>,
        accessor: "d",
      },
      {
        Header: <span className="header-style">Vendor</span>,
        accessor: "c",
      },
      {
        Header: <span className="header-style">Income Group</span>,
        accessor: "b",
      },
      {
        Header: <span className="header-style">Income Type</span>,
        accessor: "incomeType",
      },
      {
        Header: <span className="header-style">Income Sub Type</span>,
        accessor: "subType",
      },
      {
        Header: <span className="header-style">Department</span>,
        accessor: "deptName",
      },
      {
        Header: <span className="header-style">Amount</span>,
        accessor: "netAmount",
      },

      {
        Header: <span className="header-style">Date</span>,
        accessor: "a",
        Cell: (data) => {
          const calculationId = data.row.original.calculationId;
          const outputId = data.row.original.outputId;
          return (
            <div className="date-style">
              <p>12th</p>
              <div onClick={() => handleSubmitModal(calculationId)}>
                <img src={Checked} alt="checked" />
              </div>
              <div onClick={() => handleRejectModal(calculationId)}>
                <img src={Delete} alt="delete" />
              </div>
              <div onClick={() => handleMessage(calculationId, outputId)}>
                <img src={Messaged} alt="messaged" />
              </div>
            </div>
          );
        },
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
      },
      useSortBy
    );

  return (
    <div className="table-container">
      {data.length ? (
        <table {...getTableProps()} className="table custom-table">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {index === 0 ? (
                      <div>
                        <input type="checkbox" />
                      </div>
                    ) : (
                      <div>
                        {column.render("Header")}
                        <span>
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <img src={sortdown} alt="sort" />
                            ) : (
                              <img src={sortdown} alt="sort" />
                            )
                          ) : (
                            <img src={sortdown} alt="sort" />
                          )}
                        </span>
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows &&
              rows.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td className="td-text" {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
          </tbody>
        </table>
      ) : null}

      <LogModal
        show={modalShow2}
        hideModal={() => setModalShow2(false)}
        closeButton
        color="#E7FFED"
        radius="10px"
        size="lg"
        onHide={() => setModalShow2(false)}
        title={
          <div>
            <span
              style={{
                fontSize: "18px",
                fontWeight: "900",
                fontFamily: "BloggerBold",
              }}
            >
              Allocation Approved Successfully
            </span>
          </div>
        }
        body={
          <>
            <div className="horizontal-line"></div>
            <div
              style={{
                textAlign: "center",
                padding: "25px 0px 35px 0px",
              }}
            >
              <p style={{ fontFamily: "AvenirLight" }}>
                You have approved successfully the respective allocations
              </p>
            </div>
          </>
        }
      />

      <LogModal
        show={modalShow3}
        hideModal={() => setModalShow3(false)}
        closeButton
        color="#E7FFED"
        radius="10px"
        size="lg"
        onHide={() => setModalShow3(false)}
        title={
          <div>
            <span
              style={{
                fontSize: "18px",
                fontWeight: "900",
                fontFamily: "BloggerBold",
              }}
            >
              Allocation Rejected
            </span>
          </div>
        }
        body={
          <>
            <div className="horizontal-line"></div>
            <div style={{ textAlign: "center", padding: "25px 0px 35px 0px" }}>
              <p style={{ fontFamily: "AvenirLight" }}>
                You have rejected successfully, please add respective comments
                for better visibility
              </p>
            </div>
          </>
        }
      />

      <LogModal
        show={modalShow1}
        onHide={() => setModalShow1(false)}
        color="#D9D9D9"
        radius="0px"
        size="lg"
        body={
          <div className="add-message-section">
            <div className="inside-message-section">
              <p className="message-disc">
                Reducedd the allocation to the displays and increase for
                promotions
              </p>
              <div className="messaged-by">
                <p> ~ Kadir : 23rd Dec 2023</p>
              </div>
              <div>
                <textarea
                  rows={4}
                  cols={88}
                  className="text-area-style"
                  placeholder="Add Your Comment ...... "
                  onChange={(e) => handletextArea(e)}
                />
              </div>
            </div>
            {/* <div>
              <MutiSelect
                placeholder="Select the Users...."
                options={departmentoptions}
              />
            </div> */}

            <div className="success-btn" style={{ marginTop: "20px" }}>
              <Button
                className="inside-btn"
                style={{ color: "white" }}
                onClick={hnadleSubmit}
                disabled={isDisabledSubmit}
              >
                Submit
              </Button>
            </div>
          </div>
        }
      />
      <ToastContainer />
    </div>
  );
};

export default CustomTable;
