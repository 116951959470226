import Cookies from "js-cookie";
import clientApi from "../../api/client";

const loginApiSlice = clientApi.injectEndpoints({
  endpoints: (builder) => ({
    postLogin: builder.mutation({
      query: (data) => ({
        url: `/auth/login`,
        method: "POST",
        body: data,
      }),
      // transformResponse: (response) => ({
      //   set_cookies: Cookies.set("token", response?.token),
      // }),
    }),
  }),
});

export const { usePostLoginMutation } = loginApiSlice;
export const { postLogin } = loginApiSlice.endpoints;
