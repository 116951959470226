import React from "react";
import { Table } from "react-bootstrap";
import "./ModificationLogs.css";

const ModificationLogs = ({ getComment }) => {
  console.log("getComment===>", getComment);
  return (
    <div>
      <div className="body-header">
        <p>Display</p>
        <p>Floor Branded Display</p>
        <p>Personal Care</p>
      </div>
      <div>
        <Table>
          <thead
            className="bootstarp-table-header"
            style={{ borderBottom: "2px dashed grey" }}
          >
            <tr className="modification-log-hr">
              <th>User</th>
              <th>Amountdd</th>
              <th>Status</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            <tr className="bootstarp-table-tr">
              <td>Mmamoudi</td>
              <td>10,000</td>
              <td>Rejected</td>
              <td>12th Oct 2023 || 11 : 50 AM</td>
            </tr>
            <tr className="bootstarp-table-tr">
              <td>Mohsen M</td>
              <td>15,000</td>
              <td>Approved</td>
              <td>13th Oct 2023 || 10 : 50 AM</td>
            </tr>
          </tbody>
        </Table>
      </div>
      <div>
        <p className="comment-header">Comments</p>
        <div className="inside-comment-section">
          <p>Allocated as per the previous month discussion </p>
          <p className="user-section"> ~ Mmamoudi : 12th Oct 11:50am</p>
        </div>
        <div className="inside-comment-section1">
          <p>Allocate more for this department as per latest discussion </p>
          <p className="user-section"> ~ Barun : 12th Oct 11:50am</p>
        </div>
      </div>
    </div>
  );
};

export default ModificationLogs;
