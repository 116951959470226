import React, { useState } from "react";
import Select from "react-select";
import "./index.css";
import CloseIcon from "../../Images/x.svg";
import Arrow from "../../Images/svg/dropdown-arrow.svg";

export const MutiSelect = ({ placeholder, options, isMulti }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleChange = (selectedOptions) => {
    setSelectedOptions(selectedOptions);
  };

  const handleRemoveOption = (optionToRemove) => {
    setSelectedOptions(
      selectedOptions.filter((option) => option.value !== optionToRemove.value)
    );
  };

  const CustomDownArrow = () => (
    <div>
      <img src={Arrow} alt="arrow" />
    </div>
  );

  const customComponents = {
    Option: ({ innerProps, label }) => (
      <div {...innerProps} className="mutiselect-option">
        {label}
      </div>
    ),
    MultiValue: ({ index, innerProps, data }) => (
      <div {...innerProps}>{index === 0 && placeholder}</div>
    ),

    DropdownIndicator: CustomDownArrow,
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      background: "transparent",
      border: "none",
      borderBottom: "1px solid #4B4A4A",
      outline: "none",
      width: "280px",
      boxShadow: "none",
      caretColor: "transparent",
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: "16px",
      fontWeight: "400",
      fontFamily: "AvenirMedium",
    }),
    clearIndicator: (provided) => ({
      ...provided,
      display: "none",
    }),
  };

  const dropdownStyles = {
    menu: (provided) => ({
      ...provided,
      textAlign: "right",
      padding: "5px 10px 5px 0px",
      color: "grey",
    }),
  };

  return (
    <div>
      <div className="select-input">
        <div>
          <Select
            isMulti
            value={selectedOptions}
            onChange={handleChange}
            options={options}
            className="inside-select-input"
            styles={{ ...customStyles, ...dropdownStyles }}
            hideSelectedOptions={true}
            components={customComponents}
            placeholder={placeholder}
          />
          <div className="option-values">
            {selectedOptions.map((option) => (
              <div className="inside-option-values" key={option.value}>
                <div style={{ display: "flex", gap: "15px" }}>
                  <div>
                    <img
                      onClick={() => handleRemoveOption(option)}
                      src={CloseIcon}
                      alt="close"
                    />
                  </div>
                  <div>{option.label.split(" ")[0]}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MutiSelect;
