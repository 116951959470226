// import "./Margins.css";

import DashboardTable from "./UserManagemetTable/index";

const MainUserManagement = () => {
  return (
    <div>
      <DashboardTable />
    </div>
  );
};

export default MainUserManagement;
