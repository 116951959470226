import { combineReducers } from "redux";

import todoListSlice from "../slices/todoSlice";
import clientApi from "../api/client";

const MultiReducer = combineReducers({
  todoList: todoListSlice,
  [clientApi.reducerPath]: clientApi.reducer,
});

export default MultiReducer;
