/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from "react";
import "./margins.css";
import close from "../../Images/whiteX.png";

const MarginsMessage = () => {
  const [displayNotification, setDisplayNotification] = useState(true);
  // const { data, error, isLoading, isFetching } = useGetDepartmentQuery();

  const onNotificationClose = () => {
    setDisplayNotification(!displayNotification);
  };

  return (
    <>
      {displayNotification ? (
        <div className="margin_main">
          <div className="margins_message">
            Please close all the approvals before the 15th for the monthly
            closure .....
          </div>
          <img
            className="close-icon"
            onClick={onNotificationClose}
            alt="close"
            src={close}
          />
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default MarginsMessage;
