import clientApi from "../../api/client";

const unitApiSlice = clientApi.injectEndpoints({
  endpoints: (builder) => ({
    getUnits: builder.query({
      query: (token) => {
        return {
          url: `/suppliers/units`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
      },
    }),
  }),
});

export const { useGetUnitsQuery } = unitApiSlice;
export const { getUnits } = unitApiSlice.endpoints;
