import clientApi from "../../api/client";

const subtypeApiSlice = clientApi.injectEndpoints({
  endpoints: (builder) => ({
    getSubtype: builder.query({
      query: ({ parentIncomeType, parentIncomeTypeforcross, token }) => {
        console.log("parentIncomeTypeforcross1", parentIncomeTypeforcross);
        return {
          url: `/allocations/subtypes?parent=${
            parentIncomeTypeforcross
              ? parentIncomeTypeforcross
              : parentIncomeType
          }`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
      },
    }),
    getParentSubtype: builder.query({
      query: (token) => ({
        url: `/allocations/subtypes`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
      // invalidatesTags: ["getSubtypes"],
    }),
  }),
});

export const { useGetSubtypeQuery, useGetParentSubtypeQuery } = subtypeApiSlice;
export const { getSubtype } = subtypeApiSlice.endpoints;
