import MainUserManagement from "../../Components/UserManagement/UserManagement";

/* eslint-disable react/jsx-no-comment-textnodes */
import React from "react";
import "../Margins/Margins.css";
import SideNav from "../../UIComponents/SideNav/SideNav";
import NavBar from "../../UIComponents/NavBar/NavBar";
import DashboardContainer from "../DashboardContainer/DashboardContainer/DashboardContainer";

const UserManagementPage = () => {
  return (
    <div className="dashboard-wrapper">
      <SideNav />
      <div className="dashboard-right-sec">
        <NavBar />
        <div className="dashboard-main">
          <DashboardContainer>
            <MainUserManagement />
          </DashboardContainer>
          {/* <DashboardFooter /> */}
        </div>
      </div>
    </div>
  );
};

export default UserManagementPage;
