import clientApi from "../../api/client";

const summaryApiSlice = clientApi.injectEndpoints({
  endpoints: (builder) => ({
    getSummary: builder.query({
      query: ({ token, selectedVendorId, selectedMonth }) => {
        return {
          // url: `/dashboards/summary?supplier=${String(
          //   selectedVendorId
          // )}&year=2023&month=${String(selectedMonth)}`,
          url: `http://10.160.62.93/api/v1/dashboards/summary?supplier=${String(
            selectedVendorId
          )}&year=2023&month=${String(selectedMonth)}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
      },
    }),
  }),
});

export const { useGetSummaryQuery } = summaryApiSlice;
export const { getSummary } = summaryApiSlice.endpoints;
