import Cookies from "js-cookie";
import clientApi from "../../api/client";

const commentApiSlice = clientApi.injectEndpoints({
  endpoints: (builder) => ({
    getComment: builder.query({
      query: ({ calculationId, token }) => {
        console.log("calculationId", calculationId);
        return {
          url: `/allocations/${calculationId}/comments`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
      },
      providesTags: ["getComment"],
    }),
    postComment: builder.mutation({
      query: ({ comment, calculationId, outputId }) => {
        const getToken = Cookies.get("token");
        return {
          url: `/allocations/${calculationId}/comments?&outputId=${outputId}&value=${comment}`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${getToken}`,
          },
        };
      },
      invalidatesTags: ["getComment"],
    }),
  }),
});

export const { useGetCommentQuery, usePostCommentMutation } = commentApiSlice;
export const { getComment } = commentApiSlice.endpoints;
