import React, { useState, useEffect } from "react";
import "./SideNav.css";
import { Sidebar, Menu, MenuItem, useProSidebar } from "react-pro-sidebar";
import Cookies from "js-cookie";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Logo from "../../../src/assets/icons/Logo-Etqan.svg";
import Logo2 from "../../../src/assets/icons/logo_sidebar_full.svg";

import closebottom from "../../../src/assets/icons/close-bottom.svg";
import DashboardIcon from "../../../src/assets/icons/Dashboard.svg";
import BackMargin from "../../../src/assets/icons/Back-Margin.svg";
import FrontMargin from "../../../src/assets/icons/Front-Margin.svg";
import ProfileMenuIcon from "../../../src/assets/icons/Profile.svg";
import Usermanagement from "../../../src/assets/icons/User Menu Male.svg";
import LogoutIcon from "../../../src/assets/icons/Logout.svg";

import { useNavigate } from "react-router-dom";

import { useLocation } from "react-router";
import { notification } from "../Notifications/notification";

const SideNav = () => {
  const location = useLocation();
  let navigate = useNavigate();

  const { collapseSidebar, expandSidebar } = useProSidebar();
  const [activeMenuItem, setActiveMenuItem] = useState("/");
  const [sidebarIcons, setSidebarIcons] = useState(true);

  const [isLoading, setIsLoading] = useState(true);
  const navigateTo = useNavigate();

  console.log("hello sidebarIcons", sidebarIcons);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 400);
  }, []);

  useEffect(() => {
    switch (location.pathname) {
      case "/dashboard":
        setActiveMenuItem(location.pathname);
        break;
      case "/test":
        setActiveMenuItem(location.pathname);
        break;
      default:
        setActiveMenuItem(location.pathname);
        // Default case if none of the above
        //  conditions are met
        break;
    }
  }, [location]);

  const handleMenuItemClick = (route) => {
    // You can define your routes and navigate to the clicked item
    navigateTo(route);
    setActiveMenuItem(route);
    // Optionally, close the sidebar when an item is clicked
    // collapseSidebar();
  };
  const changeSidebarIcons = () => {
    console.log("hello ssssss", sidebarIcons);
    setSidebarIcons(!sidebarIcons);
  };

  const handleLogout = () => {
    Cookies.remove("token");
    notification.success({
      message: "Logout Successful",
      position: "top-right",
    });

    setTimeout(() => {
      navigate("/");
    }, 1500);
  };
  const sidebarStyles = {
    // transition: "width 0.3s ease", // Adjust the duration and easing function as needed
    transition: "width 0.001s ease-out",
  };

  return (
    <div className="sidenav-wrapper d-flex h-auto">
      <Sidebar
        rootStyles={{
          backgroundColor: "#181821",
        }}
        collapsedWidth="80px"
        defaultCollapsed={false}
        style={sidebarStyles}
        // transitionDuration={10}
        // onMouseOver={expandSidebar} // Use onMouseOver instead of onMouseEnter
        // onMouseLeave={collapseSidebar}
      >
        {!sidebarIcons ? (
          <div className="sidenav-header2 ">
            <>
              <div
                style={{
                  padding: "9px 10px 10px",
                }}
                className="logo"
              >
                <img
                  className="logo_img"
                  src={Logo}
                  alt={"panda-logo"}
                  onClick={() => {
                    changeSidebarIcons();
                    collapseSidebar();
                  }}
                  width={"50px"}
                  height={"50px"}
                />
              </div>
              {/* bg-green small white logo icon */}
            </>
          </div>
        ) : (
          <div className="sidenav-header ">
            <>
              <div
                className="logo2"
                onClick={() => {
                  changeSidebarIcons();
                  collapseSidebar();
                }}
              >
                <img
                  className="logo2_img"
                  src={Logo2}
                  alt={"panda-logo"}
                  onClick={() => {
                    changeSidebarIcons();
                    collapseSidebar();
                  }}
                  // width={"200px"}
                  // height={"100px"}
                />
                {/*  bg color white full logo */}
              </div>
            </>
          </div>
        )}

        {sidebarIcons ? (
          <>
            <div className="sidenav-menu">
              <label className="navbar-label"></label>
              <Menu
                menuItemStyles={{
                  button: ({ level, active, disabled, hover }) => {
                    // only apply styles on first level elements of the tree
                    if (level === 0) {
                      return {
                        color: disabled ? "#f5d9ff" : "#d359ff",
                        backgroundColor: active
                          ? "#ffffff" // background color for active state
                          : hover
                          ? "#ffffff" // background color for hover state
                          : undefined,
                      };
                    }
                  },
                }}
              >
                <MenuItem
                  className={`small hover_menu ${
                    activeMenuItem === "/dashboard" ? "active" : ""
                  }`}
                  style={{
                    color:
                      activeMenuItem === "/dashboard" ? "black" : undefined,
                    backgroundColor:
                      activeMenuItem === "/dashboard" ? "" : undefined,
                  }}
                  onClick={() => handleMenuItemClick("/dashboard")}
                >
                  <img
                    src={DashboardIcon}
                    alt="dashboard-icon"
                    className="menu_icon"
                  />
                  <span className="menu_text">DASHBOARD</span>
                </MenuItem>
                <MenuItem
                  className={`small 
                hover_menu
                ${activeMenuItem === "/test" ? "active" : ""}`}
                  // onClick={() => handleMenuItemClick("/test")}
                >
                  <img
                    src={BackMargin}
                    alt="menu-close"
                    className="menu_icon"
                  />
                  <span className="menu_text">BACK MARGIN</span>
                </MenuItem>
                <MenuItem className="small hover_menu">
                  <img
                    src={FrontMargin}
                    alt="menu-close"
                    // style={{ filter: "brightness(0) invert(1)" }}
                    className="menu_icon"
                  />
                  <span className="menu_text">FRONT MARGIN</span>
                </MenuItem>
                <MenuItem className="small hover_menu">
                  <img
                    src={ProfileMenuIcon}
                    alt="menu-close"
                    className="menu_icon"
                  />
                  <span className="menu_text">PROFILE</span>
                </MenuItem>
                <MenuItem
                  className={`small hover_menu ${
                    activeMenuItem === "/user-management" ? "active" : ""
                  }`}
                  style={{
                    color:
                      activeMenuItem === "/user-management"
                        ? "black"
                        : undefined,
                    backgroundColor:
                      activeMenuItem === "/user-management" ? "" : undefined,
                  }}
                  onClick={() => handleMenuItemClick("/user-management")}
                >
                  <img
                    src={Usermanagement}
                    alt="menu-close"
                    className="menu_icon"
                  />
                  <span className="menu_text">USER MANAGEMENT</span>
                </MenuItem>
                <div className="side_menu_bottom">
                  <MenuItem
                    onClick={() => {
                      changeSidebarIcons();
                      collapseSidebar();
                    }}
                    className="small hover_menu sidebar_close_btn"
                  >
                    <img
                      src={closebottom}
                      alt="menu-close"
                      className="menu_icon"
                    />
                    <span className="menu_text">CLOSE</span>
                  </MenuItem>
                  <MenuItem className="small hover_menu" onClick={handleLogout}>
                    <img
                      src={LogoutIcon}
                      alt="menu-close"
                      className="menu_icon"
                    />
                    <span className="menu_text">LOG OUT</span>
                  </MenuItem>
                </div>
              </Menu>
            </div>
          </>
        ) : (
          <>
            <div className="sidenav-menu2">
              {/* <label className="navbar-label">close menu</label> */}
              <Menu
                menuItemStyles={{
                  button: ({ level, active, disabled, hover }) => {
                    // only apply styles on first level elements of the tree
                    if (level === 0) {
                      return {
                        color: disabled ? "#f5d9ff" : "#d359ff",
                        backgroundColor: active
                          ? "#ffffff" // background color for active state
                          : hover
                          ? "#ffffff" // background color for hover state
                          : undefined,
                      };
                    }
                  },
                }}
              >
                <MenuItem
                  className={`small hover_menu_close ${
                    activeMenuItem === "/dashboard" ? "activeClose" : ""
                  }`}
                  style={{
                    color:
                      activeMenuItem === "/dashboard" ? "black" : undefined,
                    backgroundColor:
                      activeMenuItem === "/dashboard" ? "" : undefined,
                  }}
                  onClick={() => handleMenuItemClick("/dashboard")}
                >
                  <img
                    src={DashboardIcon}
                    alt="dashboard-icon"
                    className="menu_icon"
                  />
                  {/* <span className="menu_text">DASHBOARD</span> */}
                </MenuItem>
                <MenuItem
                  className={`small 
                  hover_menu_close
                 ${activeMenuItem === "" ? "activeClose" : ""}`}
                  onClick={() => handleMenuItemClick("/")}
                >
                  <img
                    src={BackMargin}
                    alt="menu-close"
                    className="menu_icon"
                  />
                  {/* <span className="menu_text">BACK MARGIN</span> */}
                </MenuItem>
                <MenuItem className="small hover_menu_close">
                  <img
                    src={FrontMargin}
                    alt="menu-close"
                    // style={{ filter: "brightness(0) invert(1)" }}
                    className="menu_icon"
                  />
                  {/* <span className="menu_text">FRONT MARGIN</span> */}
                </MenuItem>
                <MenuItem className="small hover_menu_close">
                  <img
                    src={ProfileMenuIcon}
                    alt="menu-close"
                    className="menu_icon"
                  />
                  {/* <span className="menu_text">PROFILE</span> */}
                </MenuItem>
                <MenuItem
                  className={`small hover_menu_close ${
                    activeMenuItem === "/user-management" ? "activeClose" : ""
                  }`}
                  style={
                    {
                      // color:
                      //   activeMenuItem === "/user-management"
                      //     ? "black"
                      //     : undefined,
                      //   backgroundColor:
                      //     activeMenuItem === "/user-management" ? "" : undefined,
                    }
                  }
                  onClick={() => handleMenuItemClick("/user-management")}
                >
                  <img
                    src={Usermanagement}
                    alt="menu-close"
                    className="menu_icon"
                  />
                  {/* <span className="menu_text">USER MANAGEMENT</span> */}
                </MenuItem>
                <div className="side_menu_bottom">
                  <MenuItem
                    onClick={() => {
                      changeSidebarIcons();
                      collapseSidebar();
                    }}
                    className="small hover_menu_close sidebar_close_btn"
                  >
                    <img
                      src={closebottom}
                      alt="menu-close"
                      className="menu_icon"
                    />
                    {/* <span className="menu_text">CLOSE</span> */}
                  </MenuItem>
                  <hr class="hr_bottom_logout"></hr>
                  <MenuItem
                    className="small hover_menu_close"
                    onClick={handleLogout}
                  >
                    <img
                      src={LogoutIcon}
                      alt="menu-close"
                      className="menu_icon"
                    />
                    {/* <span className="menu_text">LOG OUT</span> */}
                  </MenuItem>
                </div>
              </Menu>
            </div>
          </>
        )}
      </Sidebar>
      <ToastContainer />
    </div>
  );
};

export default SideNav;
