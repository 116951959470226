import Cookies from "js-cookie";
import clientApi from "../../api/client";

const rejectApiSlice = clientApi.injectEndpoints({
  endpoints: (builder) => ({
    postReject: builder.mutation({
      query: (data) => {
        const getToken = Cookies.get("token");
        return {
          url: `/allocations/approvals/reject`,
          method: "POST",
          body: data,
          headers: {
            Authorization: `Bearer ${getToken}`,
          },
        };
      },
    }),
  }),
});

export const { usePostRejectMutation } = rejectApiSlice;
export const { postReject } = rejectApiSlice.endpoints;
