import React, { useState } from "react";
import "./margins.css";
import Accordion from "react-bootstrap/Accordion";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import Card from "react-bootstrap/Card";
import Plus from "../../Images/plus.svg";
import Minus from "../../Images/minus.svg";
import { FloorTable } from "./FloorTable/FloorTable";
import { useGetSupplierQuery } from "../../Redux/services/allocations/supplier";
import Cookies from "js-cookie";

const AccordionComponent = ({ vendorId, selectedMonth }) => {
  const token = Cookies.get("token");
  const { data: getSupplier } = useGetSupplierQuery({
    vendorId,
    selectedMonth,
    token,
  });

  const [openCardsAccordion1, setOpenCardsAccordion1] = useState([]);
  const handleToggleAccordion1 = (cardKey) => {
    // setOpenCardsAccordion1((prevOpenCards) =>
    //   prevOpenCards.includes(cardKey)
    //     ? prevOpenCards.filter((key) => key !== cardKey)
    //     : [...prevOpenCards, cardKey]
    // );
    setOpenCardsAccordion1([cardKey]);
  };

  console.log("getSupplier", getSupplier);

  const format = (num) =>
    String(num).replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, "$1,");

  return (
    <>
      <div className="accordion_section">
        {getSupplier?.map((item) => {
          const remainingAmt =
            Number(item?.calculatedAmount) - Number(item?.allocatedAmount);
          return (
            <>
              <Accordion>
                <Card className="custom_card">
                  {/* <Card.Header className="row   custom_card_header-blk2">
                    <div className="col-2 field_name_heading">
                      {item?.incomeType}
                      <span className="field_value"></span>
                    </div>
                    <div className="col-6 field_name">
                      <div>
                        <span
                          style={{
                            fontFamily: "AvenirMedium",
                            padding: "4px 6px ",
                            fontSize: "15px",
                          }}
                        >
                          Calculated: {format(item?.calculatedAmount)}
                        </span>
                        <span
                          style={{
                            fontFamily: "AvenirMedium",
                            padding: "4px 6px ",
                            fontSize: "15px",
                          }}
                        >
                          {" "}
                          Allocated: {format(item?.allocatedAmount)}
                        </span>
                        <span
                          style={{
                            fontFamily: "AvenirMedium",
                            padding: "4px 6px ",
                            fontSize: "15px",
                          }}
                          s
                        >
                          Remaining:
                          {format(remainingAmt.toFixed(2))}
                        </span>
                      </div>
                    </div>

                    <div className="col field_name">
                      <span className="field_value span14"></span>
                    </div>

                    <div className="col-2 d-flex justify-content-end">
                      <CustomToggle
                        eventKey="0"
                        onToggle={handleToggleAccordion1}
                        isOpen={openCardsAccordion1.includes("0")}
                      ></CustomToggle>
                    </div>
                  </Card.Header> */}
                  <Card.Header className="row d-flex justify-content-start custom_card_header-blk2">
                    <div className="col-4 field_name_heading">
                      {item?.incomeType}
                      <span className="field_value"></span>
                    </div>

                    {/* <div className="col-2 field_name">
                      Calculated :
                      <span className="span14">
                        &nbsp; {format(item?.calculatedAmount)}
                      </span>
                    </div> */}

                    <div className="col-4 field_name">
                      Total Allocated :
                      <span className="span14">
                        &nbsp; {format(item?.allocatedAmount)}
                      </span>
                    </div>

                    {/* <div className="col-2 field_name">
                      Remaining :
                      <span className="span14">
                        &nbsp; {format(remainingAmt.toFixed(2))}
                      </span>
                    </div> */}

                    <div className="col-2 field_name">
                      {/* Remaining :<span className="span14">&nbsp; 9000</span> */}
                    </div>
                    <div className="col-2 d-flex justify-content-end">
                      <CustomToggle
                        eventKey="0"
                        onToggle={handleToggleAccordion1}
                        isOpen={openCardsAccordion1.includes("0")}
                      ></CustomToggle>
                    </div>
                  </Card.Header>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      <div className="row d-flex justify-content-center accordion-header">
                        <div className="col-3 field_name">
                          Calculated :
                          <span className="span14">
                            &nbsp; {format(item?.calculatedAmount)}
                          </span>
                        </div>

                        <div className="col-3 field_name">
                          Allocated :
                          <span className="span14">
                            &nbsp; {format(item?.allocatedAmount)}
                          </span>
                        </div>

                        <div className="col-3 field_name">
                          Remaining :
                          <span className="span14">
                            &nbsp; {format(remainingAmt.toFixed(2))}
                          </span>
                        </div>
                      </div>
                      <FloorTable subtypeItems={item} />
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </>
          );
        })}

        {/* <Accordion>
          <Card className="custom_card">
            <Card.Header className="row   custom_card_header-blk3">
              <div className="col-2 field_name_heading">
                Promotion
                <span className="field_value span14"></span>
              </div>
              <div className="col-2 field_name">
                Total Allocated :
                <span className="field_value span14">&nbsp; 1000</span>
              </div>
              <div className="col-2 field_name">
                Approved :<span className="field_value span14">&nbsp; 3/3</span>
              </div>
              <div className="col-2 field_name">
                Rejected :<span className="field_value span14">&nbsp; 1</span>
              </div>
              <div className="col-2 field_name">
                In Review :<span className="field_value span14">&nbsp; 1</span>
              </div>

              <div className="col-2 d-flex justify-content-end">
                <CustomToggle
                  eventKey="0"
                  onToggle={handleToggleAccordion1}
                  isOpen={openCardsAccordion1.includes("0")}
                ></CustomToggle>
              </div>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <FloorTable />
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion> */}
        {/* <Accordion>
          <Card className="custom_card">
            <Card.Header className="row   custom_card_header-blk4">
              <div className="col-2 field_name_heading">
                Other Support
                <span className="field_value"></span>
              </div>
              <div className="col-2 field_name">
                Total Allocated :<span className="field_value">1000</span>
              </div>

              <div className="col-2 field_name">
                Approved :<span className="field_value">1/2</span>
              </div>
              <div className="col-2 field_name">
                Rejected :<span className="field_value">1</span>
              </div>
              <div className="col-2 field_name">
                In Review :<span className="field_value">1</span>
              </div>
              <div className="col-2 d-flex justify-content-end">
                <CustomToggle
                  eventKey="0"
                  onToggle={handleToggleAccordion1}
                  isOpen={openCardsAccordion1.includes("0")}
                ></CustomToggle>
              </div>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <FloorTable />
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion> */}
      </div>
    </>
  );
};

export default AccordionComponent;

function CustomToggle({ children, eventKey, onToggle }) {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = useAccordionButton(eventKey, () => {
    onToggle(eventKey);
    setIsOpen(!isOpen);
  });

  return (
    <>
      <img
        src={isOpen ? Minus : Plus} // Assuming Minus is your minus icon
        alt={isOpen ? "minus" : "plus"}
        onClick={handleClick}
        width={40}
        height={20}
      ></img>
      <div> {children}</div>
    </>
  );
}
