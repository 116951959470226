import Cookies from "js-cookie";
import clientApi from "../../api/client";

const supplierApiSlice = clientApi.injectEndpoints({
  endpoints: (builder) => ({
    getSupplier: builder.query({
      query: ({ vendorId, selectedMonth, token }) => {
        return {
          url: `/allocations/supplier?supplier=${String(
            vendorId
          )}&year=2023&month=${String(selectedMonth)}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
      },
      providesTags: ["getAllowcation"],
    }),
    allocations: builder.mutation({
      query: (data) => {
        const getToken = Cookies.get("token");
        return {
          url: `http://10.160.62.93/api/v1/allocations/`,
          method: "POST",
          body: data,
          headers: {
            Authorization: `Bearer ${getToken}`,
          },
        };
      },
      invalidatesTags: ["getAllowcation"],
    }),
  }),
});

export const { useGetSupplierQuery, useAllocationsMutation } = supplierApiSlice;
export const { getSupplier } = supplierApiSlice.endpoints;
