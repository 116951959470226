import React from "react";
import "./DashboardContainer.css";

const DashboardContainer = ({ children }) => {
  return (
    <div className="dashboard-container">
      {/* <h3 className="dashboard-title">Dashboard</h3> */}
      {children}
    </div>
  );
};

export default DashboardContainer;
