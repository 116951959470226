import clientApi from "../../api/client";

const vendorApiSlice = clientApi.injectEndpoints({
  endpoints: (builder) => ({
    getVendors: builder.query({
      query: (token) => ({
        url: `/suppliers/vendors`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),
  }),
});

export const { useGetVendorsQuery } = vendorApiSlice;
export const { getVendors } = vendorApiSlice.endpoints;
