import { useState } from "react";
// import viteLogo from '/vite.svg'
import "./App.css";
import { Routes, Route } from "react-router-dom";

// import { LogarithmicScale } from 'chart.js/dist';
// import PieChart from './UIComponents/StorePieChart/PieChart/PieChart';
import NavBar from "./UIComponents/NavBar/NavBar";
import DashboardTable from "./Pages/DashboardTable";
import Login from "./Pages/Login/LoginPage";
import MarginsPage from "./Pages/Margins/MarginsPage";
import UserManagement from "./Pages/UserManagement/UserManagementPage";

function App() {
  // const [count, setCount] = useState(0)
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);

  const handleSidebarCollapse = (collapsed) => {
    setSidebarCollapsed(collapsed);
  };
  return (
    <div className="App">
      {/* <NavBar />
      <PieChart /> */}
      <Routes>
        <Route path="/" exact element={<Login />} />
        {/* <Route path="/dashboardtable" exact element={<DashboardTable />} /> */}
        <Route path="/dashboard" exact element={<MarginsPage />} />
        <Route path="/user-management" exact element={<UserManagement />} />
      </Routes>
    </div>
  );
}

export default App;
