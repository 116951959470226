import React from "react";
import "./index.css";
import CustomTable from "./customTable";

import Reset from "../../../Images/svg/reset.svg";
import Button from "react-bootstrap/Button";

const DashboardTable = () => {
  const lastIcons = () => {
    return (
      <div className="date-style1">
        <p>Active</p>
        <p style={{ textDecoration: "underline" }}>Edit</p>
      </div>
    );
  };

  const data = React.useMemo(
    () => [
      {
        checkbox: <input type="checkbox" width={18} height={18} />,
        col1: "1",
        col11: "Kadir Simsek",
        col2: "kadir.simsek@pandom.com.sa",
        col3: "Allocator",
        col4: "GNF",
        col5: "BACC",
        col6: "Personal Care Home Care",
        col7: lastIcons(),
      },
      {
        checkbox: <input type="checkbox" width={18} height={18} />,
        col1: "2",
        col11: "A Mustafa",
        col2: "kadir.simsek@pandom.com.sa",
        col3: "L1 - Approver",
        col4: "GNF",
        col5: "BACC",
        col6: "Personal Care Home Care",
        col7: lastIcons(),
      },
      {
        checkbox: <input type="checkbox" width={18} height={18} />,
        col1: "3",
        col11: "bkmukerjee",
        col2: "bkmukerjee@pandom.com.sa",
        col3: "L2 - Approver",
        col4: "GNF",
        col5: "BACC",
        col6: "Personal Care Home Care",
        col7: lastIcons(),
      },
      {
        checkbox: <input type="checkbox" width={18} height={18} />,
        col1: "4",
        col11: "Mohsen M",
        col2: "mmamoudi@pandom.com.sa",
        col3: "L3 - Approver",
        col4: "GNF",
        col5: "ALL",
        col6: "All",
        col7: lastIcons(),
      },
      {
        checkbox: <input type="checkbox" width={18} height={18} />,
        col1: "3",
        col11: "bkmukerjee",
        col2: "bkmukerjee@pandom.com.sa",
        col3: "L2 - Approver",
        col4: "GNF",
        col5: "BACC",
        col6: "Personal Care Home Care",
        col7: lastIcons(),
      },
    ],
    []
  );
  return (
    <div className="table2">
      <div>
        {/* <p className='under-class'>User Listing</p>  */}
        <input
          style={{
            border: "none",
            borderBottom: "1px solid black",
            outline: "none",
            // background: "#E7FFED",
          }}
          type="text"
          name="myInput"
          placeholder="Users Listing"
        />
      </div>

      <div className="filter-section">
        <div style={{ width: "35%" }}>
          <input
            placeholder="Search by Username, Email, Status .........."
            className="username-input"
          />
        </div>
        <Button
          style={{
            background: "#295435",
            padding: "0px 20px 0px 30px",
            width: "9%",
            height: "40px",
          }}
        >
          Success
        </Button>

        {/* <div className='clear-text'>
                    <img src={Reset} alt='reset' style={{ width: "20px", height: "20px", marginLeft: "40px" }} />
                    <p>Clear</p> 
                </div> */}
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <div>
            <img
              src={Reset}
              alt="reset"
              style={{ width: "15px", height: "15px", marginLeft: "40px" }}
            />
          </div>
          <div
            style={{
              width: "10%",
              textAlign: "center",
              fontSize: "14px",
              fontWeight: "400",
            }}
          >
            <span style={{ textDecoration: "underline" }}>Clear</span>
          </div>
        </div>
        <div
          style={{
            width: "50%",
            textAlign: "center",
            display: "flex",
            justifyContent: "end",
            gap: "20px",
          }}
        >
          <p className="under-class1">ADD USERS</p>
          <p className="under-class1">MANAGE ROLES</p>
        </div>
      </div>
      <div style={{ marginTop: "32px" }}>
        {/* <CustomTable data={data} /> */}
      </div>
    </div>
  );
};

export default DashboardTable;
