import React, { useState } from "react";
import Select from "react-select";
import "./SingleSelect.css";
import Arrow from "../../Images/svg/dropdown-arrow.svg";

export const SingleSelect = ({
  placeholder,
  handleChange,
  options,
  value,
  width,
  isMulti,
}) => {
  const CustomDownArrow = () => (
    <div>
      <img src={Arrow} alt="arrow" />
    </div>
  );

  const customComponents = {
    Option: ({ innerProps, label }) => (
      <div {...innerProps} className="singleselect-option">
        {label}
      </div>
    ),
    MultiValue: ({ innerProps, data }) => <div {...innerProps}></div>,
    DropdownIndicator: CustomDownArrow,
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      background: "transparent",
      border: "none",
      borderBottom: "1px solid #4B4A4A",
      outline: "none",
      width: width,
      boxShadow: "none",
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: "16px",
      fontWeight: "400",
    }),
    clearIndicator: (provided) => ({
      ...provided,
      display: "none",
    }),
  };
  const dropdownStyles = {
    menu: (provided) => ({
      ...provided,
      textAlign: "right",
      padding: "5px 10px 5px 0px",
      color: "grey",
      width: width,
    }),
  };
  return (
    <div>
      <div className="">
        <div>
          <Select
            value={value}
            onChange={handleChange}
            options={options}
            className=""
            styles={{ ...customStyles, ...dropdownStyles }}
            classNamePrefix=""
            components={customComponents}
            placeholder={placeholder}
          />
        </div>
      </div>
    </div>
  );
};

export default SingleSelect;
