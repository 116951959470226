import React, { useState } from "react";
import { Button, Table } from "react-bootstrap";
import SingleSelect from "../../../UIComponents/SingleSelect/SingleSelect";
import MutiSelect from "../../../UIComponents/MutliSelect";
import LogModal from "../../../UIComponents/Modal/CustomModal";
import ModificationLogs from "../../AllModals/ModificationLogs/ModificationLogs";
import ReviewLogs from "../../AllModals/ReviewLogs/ReviewLogs";
import CloseIcon from "../../../Images/X.png";
import Delete from "../../../Images/svg/trash.svg";
import Message from "../../../Images/svg/message.svg";
import "./FloorTable.css";
import { useAllocationsMutation } from "../../../Redux/services/allocations/supplier";
import { useGetDepartmentQuery } from "../../../Redux/services/department/departmentSlice";
import {
  useGetSubtypeQuery,
  useGetParentSubtypeQuery,
} from "../../../Redux/services/subtypes/subtypeSlice";
import {
  useGetCommentQuery,
  usePostCommentMutation,
} from "../../../Redux/services/comment/comment";
import { notification } from "../../../UIComponents/Notifications/notification";
import "react-toastify/dist/ReactToastify.css";

import Cookies from "js-cookie";
import { usePostApprovalsMutation } from "../../../Redux/services/allocations/approvals";
import { ToastContainer, toast } from "react-toastify";
import { useGetProfileQuery } from "../../../Redux/services/profile/profileSlice";

export const FloorTable = ({ subtypeItems }) => {
  const token = Cookies.get("token");

  const allocatedAmount = subtypeItems?.allocatedAmount;
  const calculatedAmount = subtypeItems?.calculatedAmount;
  const remainingAmount = Number(calculatedAmount) - Number(allocatedAmount);
  const parentIncomeType = subtypeItems?.incomeType;
  const [isEditable, setIsEditable] = useState(true);
  const [calculationIdForLogs, setcalculationIdForLogs] = useState();
  const [amount, setAmount] = useState("");
  const [calculationId, setCalculationId] = useState("");
  const [outputId, setOutputId] = useState("");
  const [departmentValue, setDepartmentValue] = useState();
  const [parentTypeNewLine, setParentTypeNewLine] = useState();
  const [parentTypeExistingLine, setParentTypeExistingLine] = useState();
  const [subTypeNewLine, setsubTypeNewLine] = useState();
  const [subTypeExistingLine, setsubTypeExistingLine] = useState();
  const [subTypeNewLineforIntrnal, setsubTypeNewLineforIntrnal] = useState();
  const [subTypeNewLineforCross, setsubTypeNewLineforCross] = useState();
  const [parentIncomeTypeforcross, setparentIncomeTypeforcross] = useState();
  const [modalShow1, setModalShow1] = useState(false);
  const [staticMessageModal, setStaticMessageModal] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [modalShow2, setModalShow2] = useState(false);
  const [showReviewLogs, setShowReviewLogs] = useState(false);
  const [inputs, setInputs] = useState([]);
  const [buttonStates, setButtonStates] = useState({});
  const [inputValues, setInputValues] = useState({});
  const [comment, setCommnet] = useState();

  const { data: getDepartmentDetails } = useGetDepartmentQuery(token);

  const { data: getSubtypes } = useGetSubtypeQuery({
    parentIncomeType,
    parentIncomeTypeforcross,
    token,
  });
  const [postMessage] = usePostCommentMutation();

  const { data: getParentSubtypes } = useGetParentSubtypeQuery(token);
  console.log("calculationId1", calculationId);

  const { data: getComment } = useGetCommentQuery({
    calculationId,
    token,
  });
  const { data: getProfileDetails } = useGetProfileQuery(token);

  console.log("getProfileDetails=>", getProfileDetails);
  const [allocationMutation] = useAllocationsMutation();
  const [approvalsMutation] = usePostApprovalsMutation();

  const handeApprovals = () => {
    approvalsMutation([
      {
        calculationId,
        comment: "",
      },
    ]).then((res) => {
      if (res?.error?.data?.status === "ERROR") {
        console.log("sdfsf");
        notification.error({
          message: "Approvals Rejected",
          position: "top-right",
        });
      } else {
        notification.success({
          message: "Approvals successful",
          position: "top-right",
        });
      }
    });
  };

  const handleLogsButton = () => {
    setModalShow(true);
  };
  console.log("cc", comment);

  const handleComment = (e) => {
    const { value } = e.target;

    setCommnet(value);
  };

  const [editingRowId, setEditingRowId] = useState(null);

  const handleEditButton = (calculationId) => {
    setIsEditable(false);
    setEditingRowId(calculationId);
  };

  const handleLogs = (calculationIdForLogs) => {
    setcalculationIdForLogs(calculationIdForLogs);
    setStaticMessageModal(true);
  };

  const handleExistingComment = (calculationIdForLogs) => {
    setModalShow1(true);
    setcalculationIdForLogs(calculationIdForLogs);
  };
  const handleExistingComment1 = (outputId, calculationId) => {
    console.log("calculationId", calculationId);
    setModalShow1(true);
    setOutputId(outputId);
    setCalculationId(calculationId);
  };
  const handleNewLineComment = (calculationIdForLogs) => {
    setModalShow1(true);
    setcalculationIdForLogs(calculationIdForLogs);
  };

  const handleCommentSubmit = () => {
    postMessage({
      comment,
      calculationId,
      outputId,
    }).then((res) => {
      if (res?.error?.data?.status === "ERROR") {
        notification.error({
          message: "Please enter comment",
          position: "top-right",
        });
      } else {
        notification.success({
          message: "Comment Added Successful",
          position: "top-right",
        });
        setModalShow1(false);
        setStaticMessageModal(false);
      }
    });
  };

  const handleAllocated = async () => {
    if (remainingAmount <= 0) {
      notification.error({
        message: "Exceeded Remaining Balance ",
        position: "top-right",
      });
    } else {
      try {
        await allocationMutation({
          outputId: outputId,
          dept: departmentValue,
          incomeType: parentTypeExistingLine,
          subType: subTypeExistingLine,
          netAmount: amount || 0,
          calculationId: calculationId,
          comment: "test",
        })
          .unwrap()
          .then((error) => {
            if (error.status === 400) {
              notification.error({
                message: "Please Select a Value",
                position: "top-right",
              });
            } else {
              notification.success({
                message: "Allocation Updated Successfully",
                position: "top-right",
              });
            }
          });
      } catch (error) {
        notification.error({
          message: "Please Select a Value",
          position: "top-right",
        });
      }
    }
  };

  const handleAllocatedNewLine = async () => {
    if (remainingAmount <= 0) {
      notification.error({
        message: "Exceeded Remaining Balance",
        position: "top-right",
      });
    } else {
      try {
        await allocationMutation({
          outputId: outputId,
          dept: departmentValue,
          incomeType:
            parentTypeNewLine ||
            parentIncomeTypeforcross ||
            subTypeNewLineforCross,
          subType: subTypeNewLine,
          netAmount: amount || 0,
          comment: comment,
        })
          .unwrap()
          .then((error) => {
            if (error.status === 400) {
              notification.error({
                message: "Please select value",
                position: "top-right",
              });
            } else {
              notification.success({
                message: "Allocation added successfully",
                position: "top-right",
              });
              setInputs([]);
              setsubTypeNewLineforCross();
            }
          });
      } catch (error) {
        notification.error({
          message: error?.data?.errors[0]?.message || "Please select value",
          position: "top-right",
        });
      }
    }
    setparentIncomeTypeforcross();
    setsubTypeNewLineforCross();
    setsubTypeNewLineforIntrnal();
    setDepartmentValue();
    setAmount();
  };

  const handleAddInput = () => {
    setInputs([...inputs, { firstName: "", lastName: "" }]);
    setparentIncomeTypeforcross();
    setsubTypeNewLineforCross();
    setsubTypeNewLineforIntrnal();
    setDepartmentValue();
    setAmount();
  };

  const handleDeleteInput = (index) => {
    const newArray = [...inputs];
    newArray.splice(index, 1);
    setInputs(newArray);
  };
  const subtypesParentOptions =
    getParentSubtypes?.map((item) => ({
      label: item.value ?? "",
      value: item.value ?? "",
    })) || [];
  const subtypesOptions =
    getSubtypes?.map((item) =>
      item?.subTypes?.map((elm) => ({
        label: elm.value ?? "",
        value: elm.value ?? "",
      }))
    ) || [];

  const subtypesOptionsForCross =
    getSubtypes?.map((item) =>
      item?.subTypes?.map((elm) => ({
        label: elm.value ?? "",
        value: elm.value ?? "",
      }))
    ) || [];

  const departmentOptionss =
    getDepartmentDetails?.map((item) => ({
      label: `${item.dept} - ${item.deptName}` ?? "",
      value: item.dept ?? "",
    })) || [];

  const handleDepartmentOption = (e) => {
    setDepartmentValue(e.value);
  };

  const handleParentOptions = (event) => {
    const { label } = event;
    setparentIncomeTypeforcross(label);
  };

  const handleInputChangeExistingLine = (
    e,
    calculationId,
    outputId,
    incomeType,
    subType,
    depName
  ) => {
    const { value } = e.target;
    setAmount(value);
    setCalculationId(calculationId);
    setOutputId(outputId);
    setDepartmentValue(depName);
    setParentTypeExistingLine(incomeType);
    setsubTypeExistingLine(subType);
    setInputValues((prevInputValues) => ({
      ...prevInputValues,
      [calculationId]: value,
    }));

    setButtonStates((prevButtonStates) => ({
      ...prevButtonStates,
      [calculationId]: !!value,
    }));
  };

  const handleNewAmountforNewLine = (
    e,
    outputId,
    parentIncomeType,
    incomeSubtype,
    subTypeNewLineforIntrnal,
    subTypeNewLineforCross,
    parentIncomeTypeforcross
  ) => {
    const { value } = e.target;
    setAmount(value);
    setOutputId(outputId);
    setParentTypeNewLine(parentIncomeType || parentIncomeTypeforcross);
    setsubTypeNewLine(
      incomeSubtype || subTypeNewLineforIntrnal || subTypeNewLineforCross
    );
  };

  const handleSubtypeOptions = (e) => {
    setsubTypeNewLineforIntrnal(e.value);
    setsubTypeNewLineforCross(e.value);
  };

  const isSubmitDisabledForCross = !(
    parentIncomeTypeforcross &&
    subTypeNewLineforCross &&
    departmentValue &&
    amount
  );
  const isSubmitDisabledForInternal = !(
    subTypeNewLineforIntrnal &&
    departmentValue &&
    amount
  );
  const isSubmitDisabledForDept = !(departmentValue && amount);

  const format = (num) =>
    String(num).replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, "$1,");

  const getProfile = getProfileDetails?.departments?.map((item) => item);

  console.log("getProfile", getProfile);

  return (
    <div>
      <div>
        {subtypeItems?.subTypes?.map((elm) => {
          const remainingAmt =
            Number(elm?.calculatedAmount) - Number(elm?.allocatedAmount);

          return (
            <>
              <div>
                <Table>
                  <>
                    {elm?.verticalAlloc === "N" ||
                    elm?.horizontalAlloc === "N" ? (
                      <thead
                        style={{
                          borderBottom: "2px solid grey",
                          marginTop: "5px",
                        }}
                      >
                        <tr>
                          <th style={{ width: "16%" }}>
                            <div>
                              <span
                                style={{
                                  fontFamily: "AvenirMedium",
                                  fontSize: "15px",
                                }}
                              >
                                {elm?.incomeSubtype}
                              </span>
                            </div>
                          </th>
                          <th style={{ width: "17.5%" }}>
                            <span
                              style={{
                                fontFamily: "AvenirBlack",
                                fontSize: "15px",
                              }}
                            >
                              Calculated :
                            </span>
                            <span
                              style={{
                                fontFamily: "AvenirLight",
                                fontSize: "15px",
                              }}
                            >
                              &nbsp; {format(elm?.calculatedAmount)}
                            </span>
                          </th>
                          <th style={{ width: "17%" }}>
                            <span
                              style={{
                                fontFamily: "AvenirBlack",
                                fontSize: "15px",
                              }}
                            >
                              Allocated :
                            </span>
                            <span
                              style={{
                                fontFamily: "AvenirLight",
                                fontSize: "15px",
                              }}
                            >
                              &nbsp; {format(elm?.allocatedAmount)}
                            </span>
                          </th>
                          <th style={{ width: "" }}>
                            <span
                              style={{
                                fontFamily: "AvenirBlack",
                                fontSize: "15px",
                              }}
                            >
                              Remaining :
                            </span>
                            <span
                              style={{
                                fontFamily: "AvenirLight",
                                fontSize: "15px",
                              }}
                            >
                              &nbsp; {format(remainingAmt.toFixed(2))}
                            </span>
                          </th>

                          <th style={{ width: "" }}></th>
                        </tr>
                      </thead>
                    ) : null}
                  </>
                </Table>
              </div>

              <div>
                <div>
                  <Table>
                    <>
                      {elm?.verticalAlloc === "N" &&
                      elm?.horizontalAlloc === "N"
                        ? elm.deptOutputList?.map((element) => (
                            <>
                              <tbody>
                                <tr className="bootstarp-table-tr">
                                  <td
                                    style={{
                                      padding: "6px 20px",
                                      fontSize: "14px",
                                      fontWeight: "300",
                                    }}
                                  >
                                    {element?.incomeType}
                                  </td>
                                  <td
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: 300,
                                    }}
                                  >
                                    {element?.subType}
                                  </td>
                                  <td
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: 300,
                                    }}
                                  >
                                    {element?.dept} - {element?.deptName}
                                  </td>

                                  <td
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: 300,
                                    }}
                                  >
                                    {element?.netAmount}
                                  </td>
                                  <td
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: 300,
                                    }}
                                  >
                                    <div
                                      onClick={() =>
                                        handleExistingComment1(
                                          element?.outputId,
                                          element?.calculationId
                                        )
                                      }
                                    >
                                      <img src={Message} alt="message" />
                                    </div>
                                  </td>

                                  <td>
                                    <div style={{ display: "flex" }}></div>
                                  </td>
                                </tr>
                              </tbody>
                            </>
                          ))
                        : null}
                    </>
                  </Table>
                </div>
              </div>
              <div>
                <Table>
                  <>
                    {elm?.verticalAlloc === "Y" ||
                    elm?.horizontalAlloc === "Y" ? (
                      <thead
                        style={{
                          borderBottom: "2px solid grey",
                          marginTop: "5px",
                        }}
                      >
                        <tr>
                          <th style={{ width: "16%" }}>
                            <div>
                              <span
                                style={{
                                  fontFamily: "AvenirMedium",
                                  fontSize: "15px",
                                }}
                              >
                                {elm?.incomeSubtype}
                              </span>
                            </div>
                          </th>

                          <th style={{ width: "" }}></th>
                        </tr>
                      </thead>
                    ) : null}
                  </>
                </Table>
              </div>
              <div>
                <div>
                  <Table>
                    <>
                      {elm.verticalAlloc === "Y" && elm.horizontalAlloc === "Y"
                        ? elm.deptOutputList?.map((element) => {
                            return (
                              <tbody>
                                <tr className="bootstarp-table-tr">
                                  <td
                                    style={{
                                      padding: "6px 20px",
                                      fontSize: "16px",
                                      fontWeight: "300",
                                    }}
                                  >
                                    {element?.incomeType}
                                  </td>
                                  <td
                                    style={{
                                      fontSize: "16px",
                                      fontWeight: 300,
                                      padding: "0px 20px",
                                    }}
                                  >
                                    {element?.subType}
                                  </td>
                                  {element?.calcStatus !== "REVIEW" ? (
                                    <td style={{ padding: "0px 20px" }}>
                                      {element?.dept} - {element?.deptName}
                                    </td>
                                  ) : (
                                    <td>
                                      <div>
                                        <SingleSelect
                                          options={departmentOptionss}
                                          placeholder="Select Department...."
                                          handleChange={(e) =>
                                            handleDepartmentOption(e)
                                          }
                                          width={170}
                                        />
                                      </div>
                                    </td>
                                  )}

                                  <td>
                                    <input
                                      value={
                                        inputValues[element.calculationId] ===
                                        undefined
                                          ? element?.netAmount
                                          : inputValues[element.calculationId]
                                      }
                                      onChange={(e) =>
                                        handleInputChangeExistingLine(
                                          e,
                                          element?.calculationId,
                                          element?.outputId,
                                          element?.incomeType,
                                          element?.subType,
                                          element?.dept
                                        )
                                      }
                                      style={{
                                        border: "none",
                                        borderBottom: "1px solid black",
                                        outline: "none",
                                        fontFamily: "Montserrat",
                                        width: "70%",
                                        marginTop: "12px",
                                      }}
                                      type="number"
                                      name="myInput"
                                      placeholder="Enter Amount"
                                    />
                                  </td>
                                  <td>
                                    <div
                                      style={{ display: "flex", gap: "10px" }}
                                    >
                                      <>
                                        {element?.calcStatus === "REVIEW" ? (
                                          <div>
                                            {editingRowId !==
                                            element.calculationId ? (
                                              <div>
                                                <Button
                                                  style={{
                                                    background: "#295435",
                                                    padding: "4px 25px",
                                                  }}
                                                  onClick={() =>
                                                    handleEditButton(
                                                      element.calculationId
                                                    )
                                                  }
                                                >
                                                  Edit
                                                </Button>
                                              </div>
                                            ) : (
                                              <div>
                                                <Button
                                                  style={{
                                                    background: "#295435",
                                                    padding: "4px 25px",
                                                  }}
                                                  onClick={handleAllocated}
                                                  disabled={
                                                    !buttonStates[
                                                      element?.calculationId
                                                    ]
                                                  }
                                                >
                                                  Save
                                                </Button>
                                              </div>
                                            )}
                                          </div>
                                        ) : (
                                          <div style={{ marginLeft: "82px" }} />
                                        )}

                                        <div>
                                          <Button
                                            style={{
                                              background: "white",
                                              textDecoration: "none",
                                              border: "none",
                                            }}
                                            disabled={
                                              !buttonStates[
                                                element?.calculationId
                                              ]
                                            }
                                          >
                                            <img
                                              onClick={() =>
                                                handleExistingComment(
                                                  element?.calculationId
                                                )
                                              }
                                              src={Message}
                                              alt=""
                                            />
                                          </Button>
                                        </div>
                                        {element?.calcStatus === "REVIEW" ? (
                                          <div>
                                            <Button
                                              style={{
                                                background: "#295435",
                                                padding: "5px 5px",
                                                width: "150px",
                                              }}
                                              onClick={handeApprovals}

                                              // disabled={
                                              //   !buttonStates[
                                              //     element?.calculationId
                                              //   ]
                                              // }
                                            >
                                              Send To Approve
                                            </Button>
                                          </div>
                                        ) : (
                                          <div>
                                            <Button
                                              style={{
                                                background: "#ffff",
                                                padding: "5px 5px",
                                                width: "150px",
                                                color: "black",
                                                border: "none",
                                                cursor: "none",
                                              }}
                                            >
                                              {element.calcStatus}
                                            </Button>
                                          </div>
                                        )}

                                        <div>
                                          <div onClick={handleLogsButton}>
                                            <span
                                              style={{
                                                fontSize: "14px",
                                                fontWeight: "900",
                                                fontFamily: "AvenirBlack",
                                                textDecoration: "underline",
                                                cursor: "pointer",
                                              }}
                                            >
                                              Logs
                                            </span>
                                          </div>
                                        </div>
                                      </>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            );
                          })
                        : null}

                      {elm.verticalAlloc === "Y" &&
                      elm.horizontalAlloc === "Y" &&
                      (elm.crossAlloc === "Cross" ||
                        elm.crossAlloc === "Dept" ||
                        elm.crossAlloc === "Internal") ? (
                        <>
                          {inputs.map((item, index) => (
                            <tbody key={index} className="bootstarp-table-tr">
                              <tr>
                                {elm.crossAlloc === "Cross" && (
                                  <>
                                    <td>
                                      <div>
                                        <SingleSelect
                                          options={subtypesParentOptions}
                                          placeholder="Select IncomeType...."
                                          handleChange={(e) =>
                                            handleParentOptions(e)
                                          }
                                          width={200}
                                        />
                                      </div>
                                    </td>

                                    <td>
                                      <div>
                                        <SingleSelect
                                          options={subtypesOptionsForCross[0]}
                                          placeholder="Select Subtype...."
                                          handleChange={(e) =>
                                            handleSubtypeOptions(e)
                                          }
                                        />
                                      </div>
                                    </td>

                                    <td>
                                      <div>
                                        <SingleSelect
                                          options={departmentOptionss}
                                          placeholder="Select Department...."
                                          handleChange={(e) =>
                                            handleDepartmentOption(e)
                                          }
                                        />
                                      </div>
                                    </td>

                                    <td>
                                      <input
                                        onChange={(e) =>
                                          handleNewAmountforNewLine(
                                            e,
                                            elm.outputId,
                                            parentIncomeTypeforcross,
                                            subTypeNewLineforCross
                                          )
                                        }
                                        style={{
                                          border: "none",
                                          borderBottom: "1px solid black",
                                          outline: "none",
                                          fontFamily: "Montserrat",
                                          marginTop: "12px",
                                          // width: "90%",
                                        }}
                                        type="text"
                                        name="myInput"
                                        placeholder="Enter Net Amount"
                                      />
                                    </td>
                                    {/* <td>
                                      <div style={{ height: "54px" }}>
                                        <img
                                          onClick={() =>
                                            handleNewLineComment(
                                              elm?.calculationId
                                            )
                                          }
                                          src={Message}
                                          alt=""
                                        />
                                      </div>
                                    </td> */}
                                    <td>
                                      <div style={{ display: "flex" }}>
                                        <div>
                                          <Button
                                            style={{
                                              background: "#295435",
                                              padding: "4px 25px",
                                            }}
                                            onClick={(elm) =>
                                              handleAllocatedNewLine(
                                                elm?.outputId
                                              )
                                            }
                                            disabled={isSubmitDisabledForCross}
                                          >
                                            Save
                                          </Button>
                                        </div>
                                        <div style={{ marginLeft: "8px" }}>
                                          {inputs.length ? (
                                            <img
                                              src={Delete}
                                              alt="delete"
                                              onClick={() =>
                                                handleDeleteInput(index)
                                              }
                                            />
                                          ) : null}
                                        </div>
                                      </div>
                                    </td>
                                  </>
                                )}

                                {elm.crossAlloc === "Dept" && (
                                  <>
                                    <td>{parentIncomeType}</td>
                                    <td>{elm.incomeSubtype}</td>
                                    <td>
                                      <SingleSelect
                                        options={departmentOptionss}
                                        placeholder="Select Department...."
                                        handleChange={(e) =>
                                          handleDepartmentOption(e)
                                        }
                                        width="240px"
                                      />
                                    </td>
                                    <td>
                                      <input
                                        onChange={(e) =>
                                          handleNewAmountforNewLine(
                                            e,
                                            elm.outputId,
                                            parentIncomeType,
                                            elm.incomeSubtype
                                          )
                                        }
                                        style={{
                                          border: "none",
                                          borderBottom: "1px solid black",
                                          outline: "none",
                                          fontFamily: "Montserrat",
                                        }}
                                        type="text"
                                        name="myInput"
                                        placeholder="Enter Net Amount"
                                      />
                                    </td>
                                    <td>
                                      <div
                                        onClick={() =>
                                          handleLogs(elm?.calculationId)
                                        }
                                      >
                                        <img src={Message} alt="message" />
                                      </div>
                                    </td>
                                    <td>
                                      <div style={{ display: "flex" }}>
                                        <div>
                                          <Button
                                            style={{
                                              background: "#295435",
                                              padding: "4px 25px",
                                            }}
                                            onClick={handleAllocatedNewLine}
                                            disabled={isSubmitDisabledForDept}
                                          >
                                            Save
                                          </Button>
                                        </div>
                                        <div style={{ marginLeft: "8px" }}>
                                          {inputs.length ? (
                                            <img
                                              src={Delete}
                                              alt="delete"
                                              onClick={() =>
                                                handleDeleteInput(index)
                                              }
                                            />
                                          ) : null}
                                        </div>
                                      </div>
                                    </td>
                                  </>
                                )}

                                {elm.crossAlloc === "Internal" && (
                                  <>
                                    <td>{parentIncomeType}</td>

                                    <td>
                                      <div>
                                        <SingleSelect
                                          options={subtypesOptions[0]}
                                          placeholder="Select IncomeType...."
                                          handleChange={(e) =>
                                            handleSubtypeOptions(e)
                                          }
                                        />
                                      </div>
                                    </td>
                                    <td>
                                      <div>
                                        <SingleSelect
                                          options={departmentOptionss}
                                          placeholder="Select Department...."
                                          handleChange={(e) =>
                                            handleDepartmentOption(e)
                                          }
                                        />
                                      </div>
                                    </td>

                                    <td>
                                      <div style={{ height: "54px" }}>
                                        <input
                                          onChange={(e) =>
                                            handleNewAmountforNewLine(
                                              e,
                                              elm.outputId,
                                              parentIncomeType,
                                              subTypeNewLineforIntrnal
                                            )
                                          }
                                          style={{
                                            border: "none",
                                            borderBottom: "1px solid black",
                                            outline: "none",
                                            fontFamily: "Montserrat",
                                            marginTop: "8px",
                                            width: "65%",
                                          }}
                                          type="number"
                                          name="myInput"
                                          placeholder="Enter Net Amount"
                                        />
                                      </div>
                                    </td>
                                    {/* <td>
                                      <div style={{ height: "54px" }}>
                                        <img src={Message} alt="message" />
                                      </div>
                                    </td> */}
                                    <td>
                                      <div style={{ display: "flex" }}>
                                        <div>
                                          <Button
                                            style={{
                                              background: "#295435",
                                              padding: "4px 25px",
                                            }}
                                            onClick={handleAllocatedNewLine}
                                            disabled={
                                              isSubmitDisabledForInternal
                                            }
                                          >
                                            Save
                                          </Button>
                                        </div>
                                        <div style={{ marginLeft: "8px" }}>
                                          {inputs.length ? (
                                            <img
                                              src={Delete}
                                              alt="delete"
                                              onClick={() =>
                                                handleDeleteInput(index)
                                              }
                                            />
                                          ) : null}
                                        </div>
                                      </div>
                                    </td>
                                  </>
                                )}
                              </tr>
                            </tbody>
                          ))}
                        </>
                      ) : null}

                      {elm.verticalAlloc === "Y" &&
                      elm.horizontalAlloc === "Y" ? (
                        <div style={{ marginLeft: "10px" }}>
                          <th onClick={handleAddInput}>Add +</th>
                        </div>
                      ) : null}
                    </>
                  </Table>
                </div>
              </div>
            </>
          );
        })}
      </div>

      <LogModal
        show={modalShow1}
        onHide={() => setModalShow1(false)}
        color="#D9D9D9"
        size="lg"
        body={
          <div className="add-message-section">
            <div className="inside-message-section">
              {getComment?.map((item) => {
                const inputDate = new Date(item?.createdAt);
                const options = {
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                };

                const formattedDate = new Intl.DateTimeFormat(
                  "en-US",
                  options
                ).format(inputDate);
                return (
                  <div>
                    <p className="message-disc">{item?.value}</p>
                    <div className="messaged-by">
                      <span>
                        {" "}
                        ~ {item?.fullName} : {formattedDate}
                      </span>
                    </div>
                  </div>
                );
              })}
              <div>
                <textarea
                  rows={4}
                  cols={88}
                  className="text-area-style"
                  placeholder="Add Your Comment ...... "
                  onChange={(e) => handleComment(e)}
                />
              </div>
            </div>

            <div className="success-btn" style={{ marginTop: "20px" }}>
              <Button
                className="inside-btn"
                style={{ color: "white" }}
                // onClick={() => setModalShow1(false)}
                onClick={handleCommentSubmit}
              >
                Submit
              </Button>
            </div>
          </div>
        }
      />

      <LogModal
        show={modalShow}
        hideModal={() => setModalShow(false)}
        closeButton
        color="#E7FFED"
        radius="10px"
        size="lg"
        onHide={() => setModalShow(false)}
        title={
          <div>
            <span
              style={{
                fontSize: "18px",
                fontWeight: "900",
                fontFamily: "BloggerBold",
              }}
            >
              Modification logs
            </span>
          </div>
        }
        body={
          <>
            <div className="horizontal-line"></div>
            <ModificationLogs getComment={getComment} />
          </>
        }
      />
      <LogModal
        show={modalShow2}
        hideModal={() => setModalShow2(false)}
        closeButton
        color="#E7FFED"
        radius="5px"
        size="lg"
        onHide={() => setModalShow2(false)}
        title={
          <div>
            <span
              style={{
                fontSize: "18px",
                fontWeight: "900",
                fontFamily: "BloggerBold",
              }}
            >
              Allocated Successfully
            </span>
          </div>
        }
        body={
          <>
            <div className="horizontal-line"></div>
            <div style={{ textAlign: "center" }}>
              <p className="allow-success-disc">
                You have allocated successfully, please request the respective
                approver to approve before the month closer
              </p>
              <p className="allow-successdisc">
                We have already notified him over email and notifications on the
                same.
              </p>
            </div>
          </>
        }
      />
      <LogModal
        show={showReviewLogs}
        hideModal={() => setShowReviewLogs(false)}
        closeButton={false}
        color="#D9D9D9"
        radius="10px"
        size="md"
        onHide={() => setShowReviewLogs(false)}
        body={
          <>
            <div
              style={{
                background: "#ffff",
                borderRadius: "10px",
              }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div></div>
                <div>
                  <span
                    style={{
                      fontSize: "18px",
                      fontWeight: "900",
                      color: "#295435",
                    }}
                  >
                    Review Log
                  </span>
                </div>
                <div
                  style={{ textAlign: "end", padding: "0px 5px 0px 0px" }}
                  onClick={() => setShowReviewLogs(false)}
                >
                  <img src={CloseIcon} alt="close" width={12} height={12} />
                </div>
              </div>

              <div className="dashed-horizontal-line"></div>
              <ReviewLogs />
            </div>
          </>
        }
      />
      <LogModal
        show={staticMessageModal}
        onHide={() => setStaticMessageModal(false)}
        color="#D9D9D9"
        size="lg"
        body={
          <div className="add-message-section">
            <div style={{ overflowY: "auto", height: "40vh" }}>
              <div className="inside-message-section">
                {getComment?.map((item) => {
                  const inputDate = new Date(item?.createdAt);
                  const options = {
                    day: "numeric",
                    month: "short",
                    year: "numeric",
                  };

                  const formattedDate = new Intl.DateTimeFormat(
                    "en-US",
                    options
                  ).format(inputDate);
                  return (
                    <div>
                      <p className="message-disc">{item?.value}</p>
                      <div className="messaged-by">
                        <span>
                          {" "}
                          ~ {item?.fullName} : {formattedDate}
                        </span>
                      </div>
                    </div>
                  );
                })}
                <div>
                  <textarea
                    rows={4}
                    cols={88}
                    className="text-area-style"
                    placeholder="Add Your Comment ...... "
                    onChange={(e) => handleComment(e)}
                  />
                </div>
              </div>
              <div>
                <MutiSelect placeholder="Select the Users...." />
              </div>
              <div className="success-btn">
                <Button
                  className="inside-btn"
                  style={{ color: "white" }}
                  onClick={handleCommentSubmit}
                >
                  Submit
                </Button>
              </div>
            </div>
          </div>
        }
      />
      <ToastContainer />
    </div>
  );
};
