import clientApi from "../../api/client";

const departmentApiSlice = clientApi.injectEndpoints({
  endpoints: (builder) => ({
    getDepartment: builder.query({
      query: (token) => ({
        url: `/suppliers/departments`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),
  }),
});

export const { useGetDepartmentQuery } = departmentApiSlice;
export const { getDepartment } = departmentApiSlice.endpoints;
