import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const getTodos = createAsyncThunk("todoList/getTodos", async () => {
  let response = await fetch("https://jsonplaceholder.typicode.com/todos");
  let json = await response.json();
  console.log(json);
  return json;
});

const todoListSlice = createSlice({
  name: "todoList",
  initialState: {
    todos: [
      { userId: 1, title: "Diska", id: 1, completed: false },
      { userId: 1, title: "Tvätta", id: 2, completed: true },
    ],
    status: null,
  },
  extraReducers: {
    [getTodos.fulfilled]: (state, action) => {
      //If we have to totally replace the existing array:
      // state.todos = action.payload;

      //if we want to add the json to an existing array
      let updatedTodos = state.todos.concat(action.payload);
      state.todos = updatedTodos;
      state.status = null;
    },
    [getTodos.pending]: (state) => {
      state.status = "Fetching todos. Please wait a moment...";
    },
    [getTodos.rejected]: (state) => {
      state.status = "Failed to fetch data...";
    },
  },
});

export default todoListSlice.reducer;
