import { configureStore } from "@reduxjs/toolkit";

import { persistStore } from "redux-persist";

import multiReducer from "./reducers/MultiReducer";
import clientApi from "./api/client";

export const store = configureStore({
  reducer: multiReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat([clientApi.middleware]),
});

export const persistor = persistStore(store);
