import Cookies from "js-cookie";
import clientApi from "../../api/client";

const approvalsApiSlice = clientApi.injectEndpoints({
  endpoints: (builder) => ({
    postApprovals: builder.mutation({
      query: (data) => {
        const getToken = Cookies.get("token");
        return {
          url: `http://10.160.62.93/api/v1/allocations/approvals/`,
          method: "POST",
          body: data,
          headers: {
            Authorization: `Bearer ${getToken}`,
          },
        };
      },
      invalidatesTags: ["getApprovals"],
    }),
    getApprovals: builder.query({
      query: (token) => {
        return {
          url: `/allocations/approvals/`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
      },
      providesTags: ["getApprovals"],
    }),
  }),
});

export const { usePostApprovalsMutation, useGetApprovalsQuery } =
  approvalsApiSlice;
export const { postApprovals } = approvalsApiSlice.endpoints;
